<template>
  <div class="logo-container" @click="handleClick">
    <img :style="logoStyle" src="@/assets/logos/eaglemask.png"/>
  </div>
</template>

<script>
export default {
  name: 'logo',

  props: {
    height: {
      type: Number,
      default: 20
    }
  },

  computed: {
    logoStyle () {
      return {
        height: `${this.height}px`
      }
    }
  },

  methods: {
    handleClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.logo{

}
</style>

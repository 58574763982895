import { utils, providers } from 'ethers'
import namehash from 'eth-ens-namehash'
import { Vue } from '@/antDesign'
import WalletConnectProvider from '@walletconnect/web3-provider'

const message = Vue.prototype.$message
const bscRpcUrl = process.env.VUE_APP_BSC_RPC_URL
const bscScanUrl = process.env.VUE_APP_BSC_SCAN_URL

// 驼峰字符串转横线字符串
export function camelToDashLine (camelStr) {
  return camelStr
    .replace(/[A-Z]/g, function (s) {
      return ' ' + s.toLowerCase()
    })
    .trim()
    .replace(/\s+/g, '-')
}

export function getCommunities () {
  return [
    { url: '#', title: 'Twitter' },
    { url: '#', title: 'Telegram' },
    { url: '#', title: 'Github' },
    { url: '#', title: 'Medium' },
    { url: '#', title: 'Discord' },
    { url: '#', title: 'Email' }
  ]
}

export function getUTCFormattedDate (timestampSecs) {
  const date = new Date(timestampSecs * 1000)
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  const hours =
    date.getUTCHours() > 9 ? date.getUTCHours() : '0' + date.getUTCHours()
  const minutes =
    date.getUTCMinutes() > 9
      ? date.getUTCMinutes()
      : '0' + date.getUTCMinutes()
  const seconds =
    date.getUTCSeconds() > 9
      ? date.getUTCSeconds()
      : '0' + date.getUTCSeconds()
  return `${weekdays[date.getUTCDay()]}, ${date.getUTCDate()} ${
    months[date.getUTCMonth()]
  } ${date.getUTCFullYear()} ${hours}:${minutes}:${seconds} +UTC`
}

export function getDIDNodeHash (fullName) {
  return namehash.hash(fullName)
}

export function isMobile () {
  const userAgent = window.navigator.userAgent
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    )
  return isMobile
}

export function formatEther (wei = '0') {
  const amountsEther = utils.formatEther(wei)
  return amountsEther
}

export function copy (data) {
  if (!data) return
  const inputElement = document.createElement('input')
  inputElement.value = data
  document.body.appendChild(inputElement)
  inputElement.select()

  if (document.execCommand('Copy')) {
    message.success('Copied !')
  } else {
    message.error('Copy failed !')
  }
  document.body.removeChild(inputElement)
}

export function getTxDetails (chainId, txHash) {
  switch (chainId) {
    case '0x38':
      window.open(`${bscScanUrl}/tx/${txHash}`)
      break
  }
}

export function getAddrDetails (chainId, addr) {
  switch (chainId) {
    case '0x38':
      window.open(`${bscScanUrl}/address/${addr}`)
      break
  }
}

export function errorHandler (err) {
  switch (err.code) {
    case 4001:
      message.error(err.message, 5)
      break
    case 'ACTION_REJECTED':
      message.error('User rejected transaction.', 5)
      break
    case 'INSUFFICIENT_FUNDS':
      message.error('Insufficient funds for intrinsic transaction cost.', 5)
      break
    case -32002:
      message.info('Request already pending, please view metamask.', 5)
      break
    case 'UNPREDICTABLE_GAS_LIMIT':
      // message.error(err.error.message)
      message.error(err.reason, 5)
      break
    case 4100:
      message.error(err.message, 5)
      break
    case -32602:
      message.error(err.message, 5)
      break
    case -32603:
      // RPC error
      if (err.data.code === -32000) {
        const msg = err.data.message
        message.error(msg.slice(msg.indexOf(':') + 1, msg.lastIndexOf(':'), 5))
        return
      }

      if (err.data.code === -32005) {
        const msg = err.data.details
        message.error(msg, 5)
        return
      }
      message.error(err.message, 5)
      break
    case 'CALL_EXCEPTION':
      message.error('Call revert exception.', 5)
      break
    case 'UNSUPPORTED_OPERATION':
      message.error(
        'Unsupported operation, try again after refreshing the page.',
        5
      )
      console.error('Error:', err)
      break
    default:
      console.error('Error:', err)
      console.error('Error code:', err.code)
      break
  }
}

export async function addSupportedChain (switchError) {
  let errorCode

  if (isMobile()) {
    errorCode = switchError.data.originalError.code
  } else {
    errorCode = switchError.code
  }

  if (errorCode === 4902) {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [getChainConfig()]
      })

      return true
    } catch (err) {
      errorHandler(err)
      // handle "add" error
    }
  }
  return false
}

export async function changeChain (obj) {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: obj.chainID }]
    })
  } catch (switchError) {
    addSupportedChain(switchError)
  }
}

function getChainConfig () {
  const chainId = process.env.VUE_APP_SUPPORT_CHAIN_ID

  switch (chainId) {
    case '0x38':
      return {
        chainId: chainId,
        chainName: 'BSC Mainnet',
        nativeCurrency: {
          name: 'BSC',
          symbol: 'BNB',
          decimals: 18
        },
        rpcUrls: [bscRpcUrl],
        blockExplorerUrls: [bscScanUrl]
      }
  }
}

export async function createWalletConnect () {
  let provider
  const customRpc = {
    56: bscRpcUrl
    // ...
  }

  // Create WalletConnect Provider
  const walletConnectProvider = new WalletConnectProvider({
    rpc: customRpc
  })

  //  Enable session (triggers QR Code modal)
  try {
    await walletConnectProvider.enable()

    window.walletConnectWeb3Provider = walletConnectProvider
    provider = new providers.Web3Provider(window.walletConnectWeb3Provider)

    console.log('walletConnectWeb3Provider:', window.walletConnectWeb3Provider)
  } catch (err) {
    console.log(err)
  }
  return provider
}

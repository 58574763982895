<template>
  <div class="domain-search">
    <div class="input-area">
      <div class="searchicon">
        <a-icon type="search" :style="{ fontSize: '24px' }"/>
      </div>
      <div class="input">
        <a-input
         v-model.trim="alias"
         :placeholder="$t('searchPlaceholder')"
         @keyup.enter="handleSearchClick"
         spellcheck="false"
        />
      </div>
      <div>
        <a-dropdown :trigger="['click','hover']" class="switch-suffix">
          <span class="selector">
            {{ domainSuffixes.active }}
            <a-icon type="down" class="icon"/>
          </span>
          <a-menu slot="overlay">
            <a-menu-item :key="index" v-for="(item, index) in domainSuffixes.list">
              <div class="suffix" @click="handleSuffixChange(item)">{{ item }}</div>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <div class="search" :class="{disabled: searchDisabled}"
      ref="searchBtn"
      @click="handleSearchClick">
      {{ $t('search') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'domainSearch',
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    fullName: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      beforeLoading: false,
      alias: '',
      domainSuffixes: {
        list: [
          '.e'
        ],
        active: '.e'
      }
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId', 'login']),
    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    searchDisabled () {
      if (!this.login || !this.address || this.chainId !== this.supportChainId) {
        return true
      }

      return !this.alias
    }
  },

  mounted () {
    if (this.fullName?.name) {
      this.alias = this.fullName.name
    }

    if (this.fullName?.suffix) {
      this.domainSuffixes.active = this.fullName?.suffix
    }
    this.$refs.searchBtn.click()
  },

  methods: {
    handleSearchClick () {
      this.before()
      this.nameChange()
    },

    handleSuffixChange (val) {
      this.domainSuffixes.active = val
      this.nameChange()
    },

    nameChange () {
      if (this.searchDisabled) {
        return
      }

      this.$emit('search', {
        beforeLoading: this.beforeLoading,
        alias: this.alias,
        suffix: this.domainSuffixes.active,
        fullName: this.alias + this.domainSuffixes.active
      })
    },

    before () {
      this.beforeLoading = true
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-dropdown-menu-item {
  font-size: 18px !important;
}
.domain-search{
  $height: 60px;
  width: 900px;
  height: $height;
  display: flex;

  .input-area{
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 24px;
    background: #fff;
    border-radius: $height 0 0 $height;
    border: 2px solid #CFD3F7;
    border-right: none;

    .searchicon{
      margin-top: 4px;
    }

    .input{
      flex: 1;
      :deep(.ant-input){
        font-size: 15px;
        border: none;
        box-shadow: none;
      }
    }
    .switch-suffix{
      display: inline-flex;
      width: 90px;
      height: 100%;
      text-align: left;
      align-items: center;
      border-left: 1px solid #ddd;
      justify-content: space-between;
      padding: 0 16px;
      cursor: pointer;
    }
    .selector {
      font-size: 24px;
    }
  }

  .search{
    cursor: pointer;
    width: 200px;
    background: linear-gradient(90.00deg, $blue 0%, #b443fa 100%);
    color: #FFF;
    border-radius: 0 $height $height 0;
    line-height: $height;
    user-select: none;

    &.disabled{
      background: linear-gradient(90.00deg, rgba($color: $blue, $alpha: 0.5) 0%, rgba($color: #b443fa, $alpha: 0.5) 100%);
    }
  }

  @media screen  and (max-width: $mobileWidth){
    width: 100%;
    .input-area {
      .switch-suffix {
        width: 55px;
        padding: 0 8px;
      }
    }
    .search{
      width: 100px;
    }
  }
}
</style>

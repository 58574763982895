<template>
  <div>
    <div class="my-domain" v-if="login && address && chainId === supportChainId">
      <div class="side-bar" :style="{'max-height': maxHeight}">
        <div v-if="!tip && sideBar.list.length === 0" class="tip">
          <a-icon class="loading-icon" type="loading-3-quarters"/>
          Loading...
        </div>
        <div v-else-if="tip" class="tip">
          {{ tip.split(',')[0] }}
        </div>
        <div v-else>
          <div class="did-nft">
            <div class="img" @click="changedNFTImg">
              <div class="text">Avatar Market</div>
            </div>
            <!-- <img
            src="@/assets/sale.gif"
            :style="{ 'border-radius': showCircle ? '50%': '8px' }"
            @click="changedNFTImg"
            /> -->
            <!-- <a-icon type="file-image" style="font-size: 60px; padding-top: 50px;"/> -->
          </div>
          <div
            v-for="(item, index) in sideBar.list"
            class="side-item"
            :class="{active: index === sideBar.active}"
            @click="chooseSideItem(index)"
            :key="index">
            {{ item.name.length > 13 ? item.name.slice(0, 13) + '...' : item.name }}
          </div>
        </div>
      </div>
      <div class="right-container" v-if="currentSideBarItem.name">
        <div class="content-body">
          <div class="top-bar">
            <div class="tabs">
              <div
                v-for="(item, index) in tabBar.list"
                class="tab-item"
                :class="{active: index === tabBar.active}"
                @click="chooseTabItem(index)"
                :key="index">
                {{ item.name }}
              </div>
              <div class="header-btn" @click="toHomePage">Home &gt;&gt;</div>
            </div>
          </div>
          <template v-if="tabBar.active === 0">
            <DIDSettings @changeHeight="handleChangeHeight" :currentNavMenuItem="currentSideBarItem"/>
          </template>
          <template v-if="tabBar.active === 1">
            <DIDDEX :currentNavMenuItem="currentSideBarItem"/>
          </template>
        </div>
      </div>
      <div class="right-container" v-else-if="!tip">
        <div class="pre-loading">
          <a-spin tip="Loading..." size="large" style="color: #333"/>
        </div>
      </div>
      <div v-else class="right-container">
        <div class="windmill">
          <div class="windmill-container" v-if="tip === 'No Records Found'">
            <div class="tip-text">{{ tip }}.</div>
          </div>
          <div class="tip-text" v-else>⚠️ Query failed, please try again later.</div>
        </div>
      </div>
      <a-modal
        v-model="dialog.show"
        title="Not Yet Implemented"
        @ok="() => handleOk()"
        @cancel="() => handleCancel()"
        ok-text="Confirm"
        cancel-text="Cancel"
        centered
        wrapClassName="custom-confirm"
      >
        <div class="dialog-NFT-image-container">
        <template>
          <a-carousel autoplay :speed="1" :autoplaySpeed="900" :dots="false" :slides-to-show="5" :slides-to-scroll="1">
            <div v-for="item, index in imgs" :key="index">
              <img :src="item.img">
            </div>
          </a-carousel>
        </template>
        </div>
      </a-modal>
    </div>
    <div class="not-connected" v-else>
      <div>
        <a-icon class="line1" type="warning" :style="{color: '#FFA5A5', fontSize: '132px'}"></a-icon>
        <p v-if="address" class="text">Please switch the network to {{ supportChainName }}.</p>
        <p v-else class="text">Please connect wallet and switch the network to {{ supportChainName }}.</p>
      </div>
    </div>
  </div>
</template>

<script>
import DIDSettings from '@/components/DIDSettings.vue'
import DIDDEX from '@/components/DIDDEX.vue'
import {
  errorHandler,
  formatEther,
  getDIDNodeHash
} from '@/utils/func'
import { mapGetters } from 'vuex'

export default {
  name: 'myDomain',
  components: {
    DIDSettings,
    DIDDEX
  },

  data () {
    return {
      tip: '',
      autoHeight: '1080px',
      changedNFT: false,
      isPreLoading: false,
      currentSideBarItem: {},
      sideBar: {
        active: 0,
        list: []
      },

      tabBar: {
        active: 0,
        list: [
          { name: 'Profile' },
          { name: 'Resale' }
        ]
      },

      imgs: [
        { img: require('@/assets/wel/satoshi.i.png') },
        { img: require('@/assets/wel/bitcoin.o.png') },
        { img: require('@/assets/wel/alice.i.png') },
        { img: require('@/assets/wel/polygon.o.png') },
        { img: require('@/assets/wel/bob.i.png') },
        { img: require('@/assets/wel/ethereum.o.png') },
        { img: require('@/assets/wel/satoshi.i.png') },
        { img: require('@/assets/wel/bitcoin.o.png') },
        { img: require('@/assets/wel/alice.i.png') },
        { img: require('@/assets/wel/polygon.o.png') },
        { img: require('@/assets/wel/bob.i.png') },
        { img: require('@/assets/wel/ethereum.o.png') }
      ],

      dialog: {
        show: false
      }
    }
  },

  computed: {
    ...mapGetters(['login', 'address', 'chainId']),

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    supportChainName () {
      return process.env.VUE_APP_SUPPORT_CHAIN_NAME
    },

    showCircle () {
      if (this.currentSideBarItem.suffix === '.o') {
        return true
      }
      return false
    },

    maxHeight () {
      if (this.tabBar.active === 0) return this.autoHeight

      if (this.tabBar.active === 1) return '1060px'

      return '920px'
    },

    DIDFullName () {
      return this.currentSideBarItem.name + this.currentSideBarItem.suffix
    }
  },

  watch: {
    login: {
      handler (newVal, oldVal) {
        console.log('login:', newVal, oldVal)
        this.getSidebarList()
      }
    }
  },

  created () {
    this.getSidebarList()
  },

  methods: {
    toHomePage () {
      this.$router.push({ name: 'dapp' })
    },

    chooseTabItem (index) {
      this.tabBar.active = index
    },

    async chooseSideItem (index) {
      if (this.tip) return

      this.sideBar.active = index
      this.currentSideBarItem = this.sideBar.list[index]
    },

    handleChangeHeight (val) {
      this.autoHeight = val
    },

    handleOk () {
      this.dialog.show = false
    },

    handleCancel () {
      this.dialog.show = false
    },

    async getSidebarList () {
      this.tip = ''
      this.sideBar.list.length = 0
      this.currentSideBarItem = {}

      if (!this.address) {
        if (this.$fromUrl === '/') {
          return
        }
        this.$message.info('Please connect to wallet first.')
        return
      }

      if (this.chainId !== this.supportChainId) {
        if (this.$fromUrl === '/') {
          return
        } else {
          this.$message.info(`Please switch the network to ${this.supportChainName}.`)
          return
        }
      }

      this.isPreLoading = true
      const args = [
        this.address
      ]

      console.log('searchNameList args:', args)

      this.$contracts.multiCall().searchNameList(...args)
        .then(res => {
          // 无DID
          if (res.length === 0) {
            this.tip = 'No Records Found'
            return
          }
          console.log('searchNameList:', res)

          res.forEach((ele, index) => {
            this.sideBar.list.push({ name: ele, suffix: '.e' })
          })

          // let count = 100
          // for (let i = 0; i < this.sideBar.list.length; i++) {
          //   this.sideBar.list.push(this.sideBar.list[i])
          //   count--
          //   if (count === 0) break
          // }

          this.getOrderInfo()
        })
        .catch(err => {
          // RPC网络错误
          this.tip = '⚠️ Query failed, please try again later.'
          errorHandler(err)
        })
        .finally(() => {
          this.isPreLoading = false
        })
    },
    searchOrder (item, index) {
      const functionSignature = 'searchOrder(bytes32)'
      const nodeHash = getDIDNodeHash(item.name + item.suffix)

      this.$contracts.multiCall()[functionSignature](nodeHash)
        .then(orderInfo => {
          if (orderInfo.expire.toNumber() > 0) {
            item.isOrder = true
          } else {
            item.isOrder = false
          }

          item.market = orderInfo.market
          item.taker = orderInfo.taker
          item.expire = orderInfo.expire.toNumber()
          item.price = orderInfo.price.isZero() ? 0 : formatEther(orderInfo.price)

          if (index === 0) {
            this.chooseSideItem(index)
          }

          console.log(`${item.name + item.suffix} orderInfo: ${orderInfo}`)
        }).catch(err => {
          console.log(err)
        })
    },

    changedNFTImg () {
      this.dialog.show = true
      this.changedNFT = true
    },

    async getOrderInfo () {
      this.sideBar.list.forEach((item, index) => {
        this.searchOrder(item, index)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-input{
    &:hover{
        border-color: $blue !important;
    }
    &:focus{
        border-color: $blue;
        box-shadow: 0 0 0 2px $boxShadowColor;
    }
}
// 自定义 ant 按钮样式
::v-deep .ant-btn-primary {
    background: $mainBtnColor;
    border: none;
}
::v-deep {
  .ant-modal-content {
    width: 550px;
  }
}

.dialog-NFT-image-container {
  height: 100px;
  img {
    width: 100px;
  }
}
.not-connected {
  display: flex;
  width: auto;
  height: 660px;
  margin: 0;
  background: #fff;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 6px $boxShadowColor;
  .text {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
}

.like-info {
  width: 100% !important;
}

.my-domain{
  display: flex;
  justify-content: space-between;
  text-align: left;
  .side-bar{
    min-width: 220px;
    min-height: 660px;
    background: #fff;
    margin-right: 16px;
    padding: 0 16px 16px;
    border-radius: 16px;
    overflow-y: auto;
    box-shadow: 0 0 6px $boxShadowColor;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    .tip {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0 16px;
      margin-top: 16px;
      margin-bottom: 6px;
      color: $blue;
      font-size: 16px;
      line-height: 40px;
      border-radius: 8px;
      background: $bgColor;
    .loading-icon {
      font-size: 16px;
      animation: mymove 0.8s infinite;
      animation-timing-function: linear;
      animation-delay: -2s;
    }

      @keyframes mymove {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg) }
      }
    }

    .did-nft {
      position: sticky;
      top: 0;
      width: 100%;
      height: 200px;
      margin-bottom: 12px;
      padding-top: 24px;
      background-color: #fff;
      border-radius: none;
      text-align: center;
      z-index: 1;
      .img {
        .text {
          width: 150px;
          height: 140px;
          line-height: 140px;
          margin: 0 auto;
          font-size: 18px;
          font-weight: 900;
          color: #fff;
          text-shadow: 0 0 6px $blueOth;
          user-select: none;
          background: rgba(58, 78, 255, 0.05);
          border-radius: 16px;
          cursor: pointer;
          user-select: none;
          transition: all 0.3s;

          &:hover{
            transform: scale(1.15);
            background: $bgColor;
          }
        }
      }

      // img {
      //   width: 160px;
      //   height: 150px;
      //   padding-right: 12px;
      //   padding-top: 8px;
      //   // box-shadow: 0px 0px 10px #ccc;
      //   background: rgba(58, 78, 255, 0.05);
      //   border-radius: 16px;
      //   cursor: pointer;
      //   user-select: none;
      //   transition: all 0.3s;

      //   &:hover{
      //     transform: scale(1.15);
      //     background: $bgColor;
      //   }
      // }
    }
    .side-item{
      position: relative;
      padding: 0 16px;
      margin-bottom: 6px;
      font-size: 16px;
      line-height: 40px;
      border-radius: 8px;
      cursor: pointer;
      user-select: none;
      overflow-x: hidden;
      text-overflow: ellipsis;
      &:hover {
        background: rgba(58, 78, 255, 0.06);
      }
      &.active{
        color: $blue;
        background: $bgColor;

        &::after{
          content: '';
          position: absolute;
          right: 16px;
          width: 4px;
          height: 4px;
          background: $blue;
          top: 0;
          bottom: 0;
          margin: auto;
          border-radius: 50%;
        }
      }
    }
  }
  .right-container {
    position: relative;
    flex: 1;
    min-height: 660px;
    padding: 18px 32px;
    padding-bottom: 24px;
    border-radius: 16px;
    background: #fff;
    overflow: hidden;
    box-shadow: 0 0 6px $boxShadowColor;
    // firefox
    scrollbar-width: none;
    // chrome edge safari
    &::-webkit-scrollbar {
      display: none;
    }
    .pre-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        width: 28px;
        height: 28px;
      }
    }
    .windmill {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 16px;
      .tip-text {
        color: $blue;
        white-space: nowrap;
      }
      .windmill-container {
        position: relative;
        width: 500px;
        text-align: center;
        .tip-text {
          padding: 32px 0;
        }
        .image {
          width: 100%;
          user-select: none;
          pointer-events: none;
          animation: mymove 80s infinite;
          animation-timing-function: linear;
          animation-delay: -2s;

          @keyframes mymove {
              from { transform: rotate(0deg) }
              to { transform: rotate(360deg) }
            }
          }
        .image-text {
          position: absolute;
          top: 252px;
          left: 211px;
          font-size: 18px;
          font-weight: 900;
          color: #fff;
          text-shadow: 0 0 6px $blueOth;
          cursor: pointer;
          user-select: none;
        }
      }
    }
    .content-body {
      .top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 16px;
        .header-btn {
          line-height: 36px;
          font-size: 15px;
          font-weight: 700;
          color: $blue;
          cursor: pointer;
          user-select: none;
        }
        .tabs {
          display: flex;
          color: #8791A5;
          .tab-item{
            position: relative;
            margin-right: 32px;
            font-size: 14px;
            line-height: 36px;
            white-space: nowrap;
            cursor: pointer;
            user-select: none;
            &.active{
              color: #14141E;
              font-weight: bold;

              &::after{
                content: '';
                position: absolute;
                height: 2px;
                background: $blue;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
              }
            }
          }
        }
        .agent-btn {
          width: 142px;
          height: 32px;
          margin-right: 12px;
          color: #fff;
          text-align: center;
          line-height: 32px;
          border-radius: 8px;
          border-style: none;
          cursor: pointer;
          background: $mainBtnColor;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $mobileWidth){
  .not-connected {
    margin: 0 16px;
  }
  .my-domain{
    flex-direction: column;
    padding: 0 12px;
    .side-bar{
      min-width: auto;
      min-height: auto;
      margin: 0 0 12px;

      .did-nft {
        margin: 0 auto 16px;
      }
    }
    .right-container{
      min-height: 300px;
      margin: 0;
      padding: 16px;
      .windmill {
        .windmill-container {
          .image-text {
            display: none;
          }
        }
      }
      .content-body{
        width: 100%;
        flex-direction: column;
        .top-bar{
          border-bottom: 1px solid #ddd;
          margin-bottom: 16px;
          .tabs {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
</style>

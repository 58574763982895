<template>
  <div>
    <div class="wallet-box" v-if="showDialog">
      <div class="header">
        <span class="title">Connect Wallet</span>
        <a-icon class="close-icon" type="close" @click="changeVisible"/>
      </div>
      <div class="wallet-container">
        <div class="wallet-item" @click="connectMetamaskWallet" :class="{disabled: isLoading}">
          <img class="metamask-icon" v-if="walletNameM === 'MetaMask'" src="@/assets/wallets/metamask.svg">
          <img class="metamask-icon" v-else-if="walletNameM === 'TP Wallet' " src="@/assets/wallets/tp.png">
          <img class="metamask-icon" v-else-if="walletNameM === 'BitKeep'" src="@/assets/wallets/bitKeep.png">
          <img class="metamask-icon" v-else-if="walletNameM === 'imToken'" src="@/assets/wallets/imToken.svg">
          <img class="metamask-icon" v-else-if="walletNameM === 'Safff Wallet'" src="@/assets/wallets/safff.webp">
          <span class="wallet-name">{{ walletNameM }}</span>
          <img class="loading-icon" v-show="isLoading" src="@/assets/spinner.gif"/>
        </div>
        <div class="wallet-item" @click="walletConnect" :class="{disabled: isLoading}">
          <img class="walletconnect-icon" src="@/assets/wallets/walletconnect.png">
          <span class="wallet-name">WalletConnect</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { mapGetters, mapActions } from 'vuex'
import {
  errorHandler,
  addSupportedChain,
  isMobile,
  createWalletConnect
} from '@/utils/func'

export default {
  name: 'wallet',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isLoading: false,
      wallet: {
        name: 'MeatMask'
      }
    }
  },

  computed: {
    ...mapGetters(['login', 'address', 'chainId']),
    showDialog () {
      return !this.address && !this.login
    },

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    walletNameM () {
      if (!window.ethereum) return 'MetaMask'

      if (isMobile() && window.innerWidth <= 960) {
        if (window.ethereum.isTokenPocket) {
          return 'TP Wallet'
        }

        if (window.ethereum.isImToken) {
          return 'imToken'
        }

        if (window.ethereum.isBitKeep) {
          return 'BitKeep'
        }

        if (window.ethereum.isSafematrix) {
          return 'Safff Wallet'
        }
      }
      return 'MetaMask'
    }
  },

  methods: {
    ...mapActions([
      'setLogin',
      'setChainId',
      'setAddress',
      'setReverseFullName'
    ]),

    async walletConnect () {
      // Default cached provider
      const provider = await createWalletConnect()

      if (window.walletConnectWeb3Provider && provider) {
        // Listen to EIP-1193 events to detect accounts and chain change and also disconnection.
        // Subscribe to accounts change
        window.walletConnectWeb3Provider.on('accountsChanged:', (accounts) => {
          console.log('accountsChanged:', accounts)
          this.setAddress(accounts[0])
        })

        // Subscribe to chainId change
        window.walletConnectWeb3Provider.on('chainChanged', (chainId) => {
          console.log('chainChanged:', chainId)
          this.setChainId('0x' + chainId.toString(16))
        })

        // Subscribe to session connection
        window.walletConnectWeb3Provider.on('connect', () => {
          console.log('connect')
        })

        // Subscribe to session disconnection, default cached provider is cleared,
        window.walletConnectWeb3Provider.on('disconnect', (code, reason) => {
          console.log(code, reason)
          delete window.walletConnectWeb3Provider
          this.setState(false, '', '')
          window.location.reload()
        })

        try {
          // const chainId = (await provider.getNetwork()).chainId
          const signer = await provider.getSigner()
          const chainId = '0x' + (await signer.getChainId()).toString(16)
          const address = await signer.getAddress()
          this.setState(true, address, chainId)

          console.log('Current signer:', address, 'chainId:', chainId)
        } catch (err) {
          console.log(err)
        } finally {
          this.changeVisible()
        }
      }
    },

    changeVisible () {
      this.$emit('visible', {
        visible: false
      })
      this.isLoading = false
      document.body.style.overflow = ''
    },

    cancelSign () {
      this.changeVisible()
    },

    // TODO
    async connectMetamaskWallet () {
      if (this.walletFilter()) {
        this.isLoading = true
        window.localStorage.removeItem('walletconnect')
        this.requestChainId()
      }
    },

    connectAccount () {
      this.requestAccounts()
    },

    requestChainId () {
      window.ethereum.request({
        method: 'eth_chainId'
      }).then(chainId => {
        this.setChainId(chainId)
        this.switchNetwork(chainId)
      }).catch(err => {
        this.isLoading = false
        errorHandler(err)
      })
    },

    switchNetwork (chainId) {
      if (chainId !== this.supportChainId) {
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: this.supportChainId }]
        }).then(() => {
          this.setChainId(this.supportChainId)
          this.connectAccount()
        }).catch(async err => {
          // add Chain succeeded
          if (await addSupportedChain(err)) {
            this.setChainId(this.supportChainId)
            this.connectAccount()
          } else {
            this.isLoading = false
          }
        })
      } else {
        this.connectAccount()
      }
    },

    requestAccounts () {
      window.ethereum.request({
        method: 'eth_requestAccounts'
      }).then(accounts => {
        this.setAddress(accounts[0])
        this.setLogin(true)
        this.changeVisible()
      }).catch(err => {
        errorHandler(err)
      }).finally(() => {
        this.isLoading = false
      })
    },

    walletFilter () {
      if (!window.ethereum) {
        window.open('https://metamask.io/download/')
        return false
      }

      if (isMobile() && window.innerWidth <= 960) {
        return true
      }

      // PC端过滤
      if (!window.ethereum.isMetaMask) {
        this.$message.info('Multiple Web3 plugin wallets are detected, disable them and make sure Metamask is installed and try again.')
        return false
      }

      const keys = Object.keys(window.ethereum).filter((key) => {
        return key.slice(0, 2) === 'is'
      })

      console.log('Keys:', keys)

      if (keys.length > 1) {
        this.$message.info('Multiple Web3 plugin wallets are detected, disable them and make sure Metamask is installed and try again.')
        return false
      }

      return true
    },

    setState (isLogin, address, chainId) {
      this.setLogin(isLogin)
      this.setAddress(address)
      this.setChainId(chainId)
    }
  }
}

</script>

<style lang="scss" scoped>
.wallet-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 480px;
  background: #E8E9FB;
  z-index: 1001;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0,0,0,.5);
}
.header {
  position: relative;
  padding: 24px 16px;
  width: calc(100% - 32px);
  text-align: center;
  border-bottom: 1px solid rgba(120,120,128,.2);
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: rgb(12, 12, 13);

    line-height: 21px;
    overflow: hidden;
    user-select: none;
  }

  .close-icon {
    position: absolute;
    right: 0;
    top: 12px;
    font-size: 20px;
  }
  .wallet-container {
    padding: 32px;
    width: calc(100% - 32px);

    .wallet-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 32px;
      height: 66px;
      border-radius: 12px;
      background: #fff;
      user-select: none;
      cursor: pointer;
      transition: all 0.3s;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        box-shadow: 0 0 10px #aaa;
      }
      &:active {
        opacity: 0.5;
      }
      .metamask-icon {
        width: 42px;
      }
      .walletconnect-icon {
        width: 48px;
        margin-left: 12px;
      }
      .loading-icon {
        width: 28px;
      }
      .wallet-name {
        font-size: 20px;
        font-weight: 500;
        color: #333;
      }
    }
  }

@media screen  and (max-width: $mobileWidth){
  .wallet-box {
    width: calc(100% - 32px);
    max-width: 480px;
  }
}

</style>

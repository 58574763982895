<template>
<div class="operate">
  <div class="tabs">
    <div class="tabs-container">
      <div
        v-for="(item, index) in tab.list"
        class="tab-item"
        :class="{active: index === tab.active}"
        :key="index"
        @click="changeTab(index)">
        {{ item.name }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'tabs',

  props: {
    value: Number
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  data () {
    return {
      tab: {
        list: [],
        active: 0
      }
    }
  },

  watch: {
    value: {
      handler (newValue) {
        this.tab.active = newValue
      },
      immediate: true
    }
  },

  created () {
    this.tab.list = [
      { name: this.$t('tabs.Engine') },
      { name: this.$t('tabs.myDomains') }
    ]
  },

  methods: {
    changeTab (index) {
      this.tab.active = index
      this.$emit('change', index)
    }
  }
}
</script>

<style lang="scss" scoped>
.operate {
  display: flex;
  align-items: center;

  .btn {
    margin-left: 12px;
    padding: 6px 16px;
    font-size: 16px;
    background: #fff;
    border-radius: 6px;
    user-select: none;
    // cursor: pointer;

    &:hover {
      background: #F5F6FF;
      color: $blue;
    }
  }
}
.tabs{
  // width: 312px;
  width: 380px;
  height: 40px;
  padding: 4px;
  border-radius: 40px;
  background: #FFF;
  .tabs-container{
    display: flex;
    height: 100%;
    overflow: hidden;
    border-radius: 40px;
  }
  .tab-item{
    flex: 1;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    user-select: none;
    &.active{
      background-color: $bgColor;
    }
  }
}

@media screen  and (max-width: $mobileWidth){
  .operate{
    align-self: normal;
  }

  .tabs{
    width: 100%;
    margin: auto 12px;
  }
}
</style>

<template>
  <div class="did-settings">
    <div class="data-item">
      <div class="data-title">Owner Info</div>
      <div class="data-content">
        <div class="data-desc">
          <div class="owner-balance key">Owner Address</div>
          <div class="value" :style="marginRight">{{ address }}</div>
        </div>
      </div>
      <div class="data-content">
        <div class="data-desc">
          <div class="key">Reverse Record</div>
          <div class="value" :style="marginRight">
            {{ DIDBaseInfo.reverseName }}
            <a-icon class="copy-icon" @click="copyDomainName" type="copy"/>
          </div>
        </div>
      </div>
    </div>
    <div class="data-item">
      <div class="data-title">Node Info</div>
      <div class="data-content">
        <div class="data-desc">
          <span class="key">Full Name</span>
          <div class="value">
            {{ DIDFullName }}
            <a-icon class="copy-icon" @click="copyDomainName" type="copy"/>
            <a-button
              v-if="reverse.receipt.transactionHash"
              class="btn"
              @click="viewTxDetails('reverse')"
            >
              View Details
            </a-button>
            <a-button v-else class="btn" :class="{disabled: isReverseDID || reverse.loading}" @click="setReverse">
              <a-icon class="loading-icon" v-show="reverse.loading" type="loading"/>
              Set as Reverse
            </a-button>
          </div>
        </div>
      </div>
      <div class="data-content">
        <div class="data-desc">
          <div class="key">Name Hash</div>
          <div class="value" :style="marginRight">{{ DIDNodeHash }}</div>
        </div>
      </div>
      <div class="data-content">
        <div class="data-desc">
          <div class="key">TTL</div>
          <div class="value">
            <div class="text">
              <input
              class='input'
              :class="{'hide-border': hideBorder}"
              :disabled="hideBorder" type="text"
              v-model.trim="DIDBaseInfo.ttl"
            />
            seconds
            </div>
            <a-button
              v-if="ttl.receipt.transactionHash"
              class="btn"
              @click="viewTxDetails('ttl')"
            >
              View Details
            </a-button>
            <a-button  v-else class="btn" :class="{disabled: ttl.loading}" @click="setTTL">
              <a-icon class="loading-icon" v-show="ttl.loading" type="loading"/>
              Set TTL
            </a-button>
            </div>
        </div>
      </div>
      <div class="data-content">
        <div class="data-desc">
          <div class="key">Expire</div>
          <div class="value" :style="marginRight">
            {{ 'Never expires' }}
          </div>
        </div>
      </div>
      <div class="data-content">
        <div class="data-desc">
          <div class="key">Latest Transfer</div>
          <div class="value">
            <span class="transfer-date">{{ DIDBaseInfo.transfer }}</span>
            <a-button
              v-if="transfer.receipt.transactionHash"
              class="btn"
              @click="viewTxDetails('transfer')"
            >
              View Details
            </a-button>
            <a-button  v-else class="btn" :class="{disabled: transfer.loading}" @click="transferDID">
              <a-icon class="loading-icon" v-show="transfer.loading" type="loading"/>
              Transfer
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="data-item">
      <div class="tabs-btn">
          <div class="left-container">
            <div class="did-attributes">DID Attributes</div>
            <button class="btn" @click="refreshDIDAttributes" :class="{disabled: isQuerying || keyInfo.loading}">
              Refresh
            </button>
            <div>
              <a-tabs type="card" @change="getTabKey">
              <a-tab-pane key="0" tab="Social Network">
                <!-- Content of Tab Pane 1 -->
              </a-tab-pane>
              <a-tab-pane key="1" tab="Addresses">
                <!-- Content of Tab Pane 2 -->
              </a-tab-pane>
              <a-tab-pane key="2" tab="Web & DNS">
                <!-- Content of Tab Pane 3 -->
              </a-tab-pane>
            </a-tabs>
            </div>
          </div>
          <div class="icons">
            <a-tooltip placement="top">
            <span slot="title">Edit DID attributes</span>
            <img class="edit-icon" :class="{disabled: keyInfo.loading}" src="@/assets/myDomain/edit.png" @click="editInfo">
          </a-tooltip>
          <!-- <a-tooltip placement="top">
            <span slot="title">Temporarily save changes</span>
            <img class="save-icon" src="@/assets/myDomain/save.png" :class="{disabled: true}"/>
          </a-tooltip> -->
          <template v-if="keyInfo.receipt.transactionHash">
            <a-tooltip placement="top">
            <span slot="title">View details</span>
            <img
              class="view-details"
              src="@/assets/myDomain/view-details.png"
              @click="viewTxDetails('keyInfo')"
            />
          </a-tooltip>
          </template>
          <template v-else>
            <a-tooltip placement="topRight">
            <span slot="title">Save changes to blockchain</span>
            <a-icon v-show="keyInfo.loading" class="loading-icon" type="loading"/>
            <img
              class="save-blockchain-icon"
              :class="{disabled: keyInfo.loading}"
              src="@/assets/myDomain/save-blockchain.png"
              @click="batchSetKeyInfo"
            />
          </a-tooltip>
          </template>
          </div>
      </div>
      <template v-if="tabKey === '0'">
          <div class="basic-info" v-for="item, index in socialNetworks" :key="index">
            <span class="info-name">{{ item.name }}</span>
            <div class="info-loading">
              <div v-if="isQuerying">
                <span class="loading-icon"><a-icon type="loading"/></span>
                <input :readonly="isReadonly"/>
              </div>
              <input v-else v-model.trim="item.value" placeholder="Not set" :readonly="keyInfo.loading || isReadonly"/>
            </div>
            <a-tooltip>
              <template slot="title">
                <span>Modified</span>
              </template>
              <a-icon v-if="isModified(index, 'socialNetworks')" type="check-square" class="check-square"/>
            </a-tooltip>
          </div>
        </template>
        <template v-if="tabKey === '1'">
          <div
            class="basic-info"
            v-for="item, index in addresses" :key="index"
            :style="{'margin-bottom': item.name === 'AVAX' ? '32px' : '0'}"
            >
            <span class="info-name">
            {{ item.name }}
            </span>
            <div class="info-loading">
              <div v-if="isQuerying">
                <span class="loading-icon"><a-icon type="loading"/></span>
                <input :readonly="isReadonly"/>
              </div>
              <input
                v-else
                v-model.trim="item.value"
                placeholder="Not set"
                :readonly="keyInfo.loading || isReadonly"
              />
            </div>
            <a-tooltip>
              <template slot="title">
                <span>Modified</span>
              </template>
              <a-icon v-if="isModified(index, 'addresses')" type="check-square" class="check-square"></a-icon>
            </a-tooltip>
          </div>
        </template>
        <template v-if="tabKey === '2'">
          <div class="basic-info" v-for="item, index in webDNS" :key="index">
            <span class="info-name">{{ item.name }}</span>
            <div class="info-loading">
              <div v-if="isQuerying">
                <span class="loading-icon"><a-icon type="loading"/></span>
                <input :readonly="isReadonly"/>
              </div>
              <input v-else v-model.trim="item.value" placeholder="Not set" :readonly="keyInfo.loading || isReadonly"/>
            </div>
            <a-tooltip>
              <template slot="title">
                <span>Modified</span>
              </template>
              <a-icon v-if="isModified(index, 'webDNS')" type="check-square" class="check-square"/>
            </a-tooltip>
          </div>
        </template>
    </div>
  <a-modal
    v-model="showDialog"
    title="Enter recipient's address"
    @ok="() => handleOk()"
    @cancel="() => handleCancel()"
    ok-text="Confirm"
    cancel-text="Cancel"
    centered
    wrapClassName="custom-confirm"
  >
    <div class="dialog-container">
      <a-input class="recipient-input" v-model.trim="recipient"/>
    </div>
  </a-modal>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import
{
  copy,
  isMobile,
  getTxDetails,
  getDIDNodeHash,
  getUTCFormattedDate,
  errorHandler
} from '@/utils/func'

import { isAddress } from 'web3-utils'
import { BigNumber } from 'ethers'

export default {
  name: 'DIDSettings',
  props: {
    currentNavMenuItem: {
      type: Object,
      require: true
    }
  },

  data () {
    return {
      hideBorder: true,
      showDialog: false,
      recipient: '',
      marginRight: 'margin-right: 166px;',
      reverse: {
        receipt: {},
        loading: false
      },

      ttl: {
        receipt: {},
        loading: false
      },

      transfer: {
        receipt: {},
        loading: false
      },

      keyInfo: {
        receipt: {},
        loading: false
      },

      cache: {
        webDNS: [],
        addresses: [],
        socialNetworks: []
      },

      socialNetworks: [
        { name: 'Description', key: 'KEY_DESCRIPTION', value: '' },
        { name: 'Email', key: 'KEY_EMAIL', value: '' },
        { name: 'Github', key: 'KEY_GITHUB', value: '' },
        { name: 'Discord', key: 'KEY_DISCORD', value: '' },
        { name: 'Twitter', key: 'KEY_TWITTER', value: '' },
        { name: 'Telegram', key: 'KEY_TELEGRAM', value: '' },
        { name: 'Instagram', key: 'KEY_INSTAGRAM', value: '' }
      ],

      addresses: [
        // EVM Chain
        { name: 'ETH', key: 'KEY_ADDRESS_ETH', value: '' },
        { name: 'ETC', key: 'KEY_ADDRESS_ETC', value: '' },
        { name: 'BNB', key: 'KEY_ADDRESS_BNB', value: '' },
        { name: 'BOOL', key: 'KEY_ADDRESS_BOOL', value: '' },
        { name: 'MATIC', key: 'KEY_ADDRESS_MATIC', value: '' },
        { name: 'OPT', key: 'KEY_ADDRESS_OPT', value: '' },
        { name: 'ARB', key: 'KEY_ADDRESS_ARB', value: '' },
        { name: 'ZKS', key: 'KEY_ADDRESS_ZKS', value: '' },
        { name: 'CRO', key: 'KEY_ADDRESS_CRO', value: '' },
        { name: 'FTM', key: 'KEY_ADDRESS_FTM', value: '' },
        { name: 'FVM', key: 'KEY_ADDRESS_FVM', value: '' },
        { name: 'AVAX', key: 'KEY_ADDRESS_AVAX', value: '' },

        { name: 'BTC', key: 'KEY_ADDRESS_BTC', value: '' },
        { name: 'DOGE', key: 'KEY_ADDRESS_DOGE', value: '' },
        { name: 'SOL', key: 'KEY_ADDRESS_SOL', value: '' },
        { name: 'ADA', key: 'KEY_ADDRESS_ADA', value: '' },
        { name: 'DOT', key: 'KEY_ADDRESS_DOT', value: '' },
        { name: 'KSM', key: 'KEY_ADDRESS_KSM', value: '' },
        { name: 'FIL', key: 'KEY_ADDRESS_FIL', value: '' },
        { name: 'APT', key: 'KEY_ADDRESS_APT', value: '' }
      ],

      webDNS: [
        { name: 'IPv4', key: 'KEY_IPV4', value: '' },
        { name: 'IPv6', key: 'KEY_IPV6', value: '' },
        { name: 'DNS', key: 'KEY_DNS', value: '' },
        { name: 'Domain', key: 'KEY_DOMAIN', value: '' },
        { name: 'Boot Nodes', key: 'KEY_BOOT_NODES', value: '' },
        { name: 'Relay Nodes', key: 'KEY_RELAY_NODES', value: '' }
      ],
      isQuerying: true,
      DIDBaseInfo: {
        ttl: 0,
        transfer: null,
        reverseName: ''
      },
      tip: '',
      tabKey: '0',
      isReadonly: true,
      isEdited: false
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId']),
    zeroAddr () {
      return process.env.VUE_APP_ZERO_ADDR
    },

    DIDNodeHash () {
      return getDIDNodeHash(this.DIDFullName)
    },

    DIDFullName () {
      return this.currentNavMenuItem.name + this.currentNavMenuItem.suffix
    },

    isReverseDID () {
      return this.DIDBaseInfo.reverseName === this.DIDFullName
    }
  },

  watch: {
    currentNavMenuItem: {
      handler (newVal, oldVal) {
        if (newVal.name !== oldVal.name || newVal.suffix !== oldVal.suffix) {
          this.recipient = ''
          this.ttl.receipt = {}
          this.transfer.receipt = {}
          this.reverse.receipt = {}
          this.keyInfo.receipt = {}
          this.hideBorder = true
          this.isEdited = false
          this.isEdited = this.checkInputStatus()
          if (!this.isEdited) {
            this.isQuerying = true
            this.batchResolveKeyInfo()
          } else {
            this.batchResolveKeyInfo('reverse')
          }
        }
      },
      deep: true
    }
  },

  created () {
    window.onresize = () => {
      if (window.innerWidth > 960) {
        this.marginRight = 'margin-right: 166px;'
      } else {
        this.marginRight = 'margin-right: 0;'
      }
    }
  },

  mounted () {
    this.batchResolveKeyInfo()
  },

  methods: {
    ...mapActions([
      'setAddress',
      'setChainId',
      'setReverseFullName'
    ]),

    isEvmAddress (item) {
      return this.addresses.slice(0, 12).some(ele => ele.key === item.key)
    },

    handleOk () {
      if (!this.recipient) {
        this.$message.info('Please enter the recipient\'s address.')
        return
      }

      if (this.recipient.toLocaleLowerCase() === this.address.toLocaleLowerCase()) {
        this.$message.info('Recipient cannot be the owner of current DID, please re-enter.')
        return
      }

      if (!isAddress(this.recipient)) {
        this.$message.info('Invalid address, please check and try again.')
        return
      }
      this.safeTransferFrom()
    },

    handleCancel () {
      this.transfer.loading = false
    },

    transferDID () {
      this.showDialog = true
    },

    getTabKey (key) {
      this.tabKey = key

      if (isMobile && window.innerWidth <= 960) {
        return '920px'
      }

      if (this.tabKey === '1') {
        this.$emit('changeHeight', '1500px')
      } else {
        this.$emit('changeHeight', '1080px')
      }
    },

    isModified (index, flagStr) {
      switch (flagStr) {
        case 'socialNetworks':
          return this.socialNetworks[index].value &&
          this.socialNetworks[index].value !==
          this.cache.socialNetworks[index]
        case 'addresses':
          return this.addresses[index].value &&
          this.addresses[index].value !==
          this.cache.addresses[index]
        case 'webDNS':
          return this.webDNS[index].value &&
          this.webDNS[index].value !==
          this.cache.webDNS[index]
      }
    },

    refreshDIDAttributes () {
      this.isQuerying = true
      this.keyInfo.receipt = {}
      this.batchResolveKeyInfo('keyInfo')
    },

    setTTL () {
      if (this.hideBorder) {
        this.hideBorder = false
        return
      }

      this.ttl.loading = true
      let ttl

      try {
        ttl = BigNumber.from(this.DIDBaseInfo.ttl)
      } catch (err) {
        this.ttl.loading = false
        this.$message.error('TTL value error, please re-enter.')
        return
      }

      const args = [
        this.DIDNodeHash,
        ttl
      ]

      console.log('setTTL args:', args)

      this.$contracts.editor(true).setTTL(...args)
        .then(result => {
          return result.wait()
        })
        .then(receipt => {
          this.ttl.receipt = receipt
          this.batchResolveKeyInfo('ttl')
          this.$message.success('Successfully set!')
        })
        .catch(err => {
          if (err.code === 'INVALID_ARGUMENT') {
            this.$message.error('Exceeding ttl maximum value (18446744073709551615) error, please re-enter.')
          }
          errorHandler(err)
        })
        .finally(() => {
          this.ttl.loading = false
        })
    },

    setReverse () {
      this.reverse.loading = true
      console.log('setReverse arg:', this.DIDNodeHash)

      this.$contracts.editor(true).setReverse(this.DIDNodeHash)
        .then(result => {
          return result.wait()
        })
        .then(receipt => {
          this.reverse.receipt = receipt
          this.batchResolveKeyInfo('reverse')
          this.$message.success('Successfully set!')
        })
        .catch(err => {
          errorHandler(err)
        })
        .finally(() => {
          this.reverse.loading = false
        })
    },

    safeTransferFrom () {
      this.transfer.loading = true
      this.showDialog = false

      const args = [
        this.address,
        this.recipient,
        this.DIDNodeHash
      ]

      console.log('Transfer DID args:', args)

      const functionSignature = 'safeTransferFrom(address,address,uint256)'

      this.$contracts.treeDB(true)[functionSignature](...args)
        .then(result => {
          return result.wait()
        })
        .then(receipt => {
          this.transfer.receipt = receipt
          this.batchResolveKeyInfo('transfer')
          this.$message.success('Successfully transfer!')
        })
        .catch(err => {
          errorHandler(err)
        })
        .finally(() => {
          this.transfer.loading = false
        })
    },

    batchSetKeyInfo () {
      let errorFlag
      const textKeys = []
      const textValues = []
      const evmAddrKeys = []
      const evmAddrValues = []

      this.addresses.forEach((item, index) => {
        if (errorFlag) return
        if (this.isModified(index, 'addresses')) {
          if (this.isEvmAddress(item)) {
            if (isAddress(item.value)) {
              evmAddrKeys.push(item.key)
              evmAddrValues.push(item.value)
            } else {
              errorFlag = true
              const name = item.key.split('_')[2]
              this.$message.error(`${name} entry in the Addresses tag requires an Ethereum format address.`)
            }
          } else {
            textKeys.push(item.key)
            textValues.push(item.value)
          }
        }
      })

      if (errorFlag) return

      this.socialNetworks.forEach((item, index) => {
        if (this.isModified(index, 'socialNetworks')) {
          textKeys.push(item.key)
          textValues.push(item.value)
        }
      })

      this.webDNS.forEach((item, index) => {
        if (this.isModified(index, 'webDNS')) {
          textKeys.push(item.key)
          textValues.push(item.value)
        }
      })

      this.keyInfo.loading = true

      const args = [
        this.DIDNodeHash,
        textKeys,
        textValues,
        evmAddrKeys,
        evmAddrValues
      ]

      console.log('batchSetKeyInfo args:', args)
      this.$contracts.editor(true).batchSetKeyInfo(...args)
        .then(result => {
          return result.wait()
        })
        .then(receipt => {
          this.keyInfo.receipt = receipt
          this.batchResolveKeyInfo('keyInfo')
          this.$message.success('Successfully set!')
        })
        .catch(err => {
          errorHandler(err)
        })
        .finally(() => {
          this.keyInfo.loading = false
        })
    },

    async batchResolveKeyInfo (flagStr) {
      const names = [this.currentNavMenuItem.name, this.currentNavMenuItem.suffix[1]]

      console.log('batchResolveKeyInfo args:', names)

      try {
        const info = await this.$contracts.resolver().batchResolveKeyInfo(names)

        if (!flagStr || flagStr === 'reverse' || flagStr === 'ttl' || flagStr === 'transfer') {
          this.DIDBaseInfo.ttl = info.ttl.toNumber()
          this.DIDBaseInfo.transfer = getUTCFormattedDate(info.transfer.toNumber())
          this.DIDBaseInfo.reverseName = info.reverseName
          this.setReverseFullName(info.reverseName)
        }

        console.log('batchResolveKeyInfo', info)

        if (flagStr && flagStr !== 'keyInfo') return

        this.socialNetworks.forEach((item, index) => {
          const indexOfValue = info[7].findIndex(key => key === item.key)
          item.value = info.texts[indexOfValue]
          this.cache.socialNetworks[index] = item.value
        })

        this.addresses.forEach((item, index) => {
          const indexOfValue = info[7].findIndex(key => key === item.key)
          if (this.isEvmAddress(item)) {
            if (info.addressList[indexOfValue] === this.zeroAddr) {
              item.value = ''
            } else {
              item.value = info.addressList[indexOfValue]
            }
          } else {
            item.value = info.texts[indexOfValue]
          }
          this.cache.addresses[index] = item.value
        })

        this.webDNS.forEach((item, index) => {
          const indexOfValue = info[7].findIndex(key => key === item.key)
          item.value = info.texts[indexOfValue]
          this.cache.webDNS[index] = item.value
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.isQuerying = false
      }
    },

    viewTxDetails (flagStr) {
      let txHash
      switch (flagStr) {
        case 'transfer':
          txHash = this.transfer.receipt.transactionHash
          break
        case 'ttl':
          this.hideBorder = true
          txHash = this.ttl.receipt.transactionHash
          break
        case 'reverse':
          txHash = this.reverse.receipt.transactionHash
          break
        case 'keyInfo':
          txHash = this.keyInfo.receipt.transactionHash
          break
      }
      getTxDetails(this.chainId, txHash)
    },

    checkInputStatus () {
      const checkSocialNetworks = () => {
        return this.socialNetworks.some((item, index) => {
          return item.value !== this.cache.socialNetworks[index]
        })
      }

      const checkAddresses = () => {
        return this.addresses.some((item, index) => {
          return item.value !== this.cache.addresses[index]
        })
      }

      const checkWebDNS = () => {
        return this.webDNS.some((item, index) => {
          return item.value !== this.cache.webDNS[index]
        })
      }

      if (checkSocialNetworks() || checkAddresses() || checkWebDNS()) {
        return true
      }

      return false
    },

    copyDomainName () {
      const data = this.DIDFullName
      copy(data)
    },

    editInfo () {
      this.keyInfo.receipt = {}
      this.isReadonly = !this.isReadonly
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ant-input{
  &:hover{
    border-color: $blue;
  }
  &:focus{
    border-color: $blue;
    box-shadow: 0 0 0 2px $boxShadowColor;
  }
}
.recipient-input {
}

.did-settings{
  .data-item {
    margin-top: 36px;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }

    .tabs-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 48px;
      margin-bottom: 24px;

      .left-container {
        flex: 1;
        display: flex;
        justify-content: flex-start;

        ::v-deep .ant-tabs-nav .ant-tabs-tab {
          /* 设置 Tabs 标签字体颜色 */
          color: #2C3E50;
          /* 设置 Tabs 标签鼠标悬停时的样式 */
          &:hover {
            // color: $blue;
            background: rgba(58, 78, 255, 0.06) !important;
          }
        }

        /* 设置选中 Tabs 标签的字体颜色 */
        // ::v-deep .ant-tabs-nav .ant-tabs-tab-active {
        //   color: $blue !important;
        // }
        .did-attributes {
          margin-top: 6px;
          color: $blue;
          font-size: 16px;
          font-weight: 700;
          text-align: left;
        }
        .btn {
          width: 68px;
          height: 32px;
          margin: 4px 24px 0;
          color: #fff;
          border-radius: 8px;
          background: $mainBtnColor;
          border-style: none;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }
      .icons {
        padding-bottom: 20px;
        .loading-icon {
          padding: 0 10px 0 24px;
          color: $blue;
          font-size: 14px;
          vertical-align: middle;
        }
        .edit-icon, .save-icon, .save-blockchain-icon, .view-details {
          margin-left: 8px;
          cursor: pointer;
          user-select: none;
          transition: all 0.3;

          &:hover {
            opacity: 0.7;
          }
          &:active {
            opacity: 1;
          }
        }
        .edit-icon {
          width: 39px;
          height: 38px;
          padding-top: 2px;
        }
        .save-icon {
          width: 32px;
          height: 38px;
          padding-top: 3px;
        }
        .save-blockchain-icon {
          width: 34px;
          height: 40px;
          padding-top: 4px;
        }
        .view-details {
          width: 38px;
          padding-top: 4px;
        }
      }
    }
  }
  .data-title {
    text-align: left;
    color: $blue;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 28px;
  }
  .data-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;

      .data-desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        white-space: nowrap;
      }
      .key {
        width: 45%;
        font-size: 16px;
      }
      .value {
        flex: 1;
        font-size: 14px;
        text-align: right;
        color: $valueColor;
        white-space: nowrap;
        .hide-border {
          text-align: right !important;
          border: none !important;
        }
        .text {
          display: inline-block;
          .input {
            text-align: center;
            line-height: 1;
            border-radius: 4px;
            border: 1px solid #ddd;
            background: #fff;
            outline: none;
          }
        }
        .copy-icon {
          margin-left: 6px;
          font-size: 14px;
          cursor: pointer;
          color: #333;
          &:active {
            color: #b15be6;
          }
        }
        .btn {
          width: 142px;
          height: 32px;
          margin-left: 24px;
          color: #fff;
          cursor: pointer;
          line-height: 32px;
          border-style: none;
          border-radius: 8px;
          text-align: center;
          background: $mainBtnColor;

          &:hover {
            opacity: 0.7;
          }
          .loading-icon {
            padding-right: 6px;
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
    .basic-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      .edit-dot {
        display: flex;
        justify-content: flex-start;
        color: #be78e9;
        .dot {
          font-size: 24px;
          margin-left: 8px;
      }
    }

      .info-name {
      width: 25%;
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      }

      .info-loading {
      flex: 1;
      position: relative;

      .loading-icon {
        position: absolute;
        color: $blue;
      }

      input {
        width: 100%;
        height: 24px;
        border: none;
        border-bottom: 2px solid transparent;
        text-align: left;
        padding-left: 3px;
        outline: none;
        font-size: 15px;
        color: $valueColor;
        &:hover {
          border-bottom: 2px solid #E5E5E5;
        }
      }
    }

    .check-square {
        position: reactive;
        font-size: 16px;
        color: #42E068;
      }
  }
  }

  @media screen and (max-width: $mobileWidth){
  .did-settings {
    .data-item {
      .data-content {
        .data-desc {
          flex-direction: column;
          align-items: flex-start;
          .value {
            padding: 16px 0;
            width: 100%;
            white-space: normal;
            overflow-wrap: break-word;
            .text {
              .input {
                width: 60px;
                margin: 4px 0 16px;
                text-align: left !important;
              }
            }
            .transfer-date {
              line-height: 42px;
            }
          }
        }
      }
      .tabs-btn {
        display: flex;
        flex-direction: column;
        align-items: center;

        .left-container {
          flex-direction: column;
          align-items: center;
          .did-attributes {
            // margin: 0 0 32px;
          }
          .btn {
            margin: 24px 0;
          }
        }
      }
    }
  }
  .basic-info {
    .info-name {
      margin-right: 24px;
    }
  }
}
</style>

<template>
  <div class="did-dex">
    <div class="did-dex-container">
      <div class="content-container">
        <div class="info">
          <div class="row" style="height: 48px">
            <div class="domain-name">
              {{ DIDFullName }}
            </div>
            <a-icon class="copy-icon" type="copy" @click="copyDomainName" />
          </div>
          <template v-if="currentNavMenuItem.isOrder">
            <div class="row" style="height: 48px">
              <div class="label bold f16">Taker:</div>
              <div class="content">
                <div>{{ orderTaker }}</div>
              </div>
            </div>
            <div class="row" style="height: 56px">
              <div class="label bold f16">Current price</div>
              <div class="content">
                <span class="price">
                  {{ currentPriceByEther }}
                  <span class="symbol">USDT</span>
                  <img class="usdt-token" src="@/assets/network/usdt.png">
                </span>
              </div>
            </div>
            <div class="row" style="height: 56px;">
              <div class="label bold f16">Expiration time</div>
              <div class="content">
                <div>{{ getUTCFormattedDate(currentNavMenuItem.expire) }}</div>
              </div>
            </div>
          </template>
          <template v-if="!currentNavMenuItem.isOrder || showSettingInput">
            <div class="row" style="height: 80px">
              <div class="label bold f16">Price</div>
              <div class="content">
                <a-tooltip>
                  <template slot="title">
                    <span>Selling price</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </div>
            </div>
            <div style="margin-bottom: 32px; position: relative;" >
              <a-input v-model.trim="input.value" size="large" :disabled="action.loading" placeholder="Enter the price you want to sell">
                <a-select slot="addonAfter" default-value="USDT" style="width: 100px" dropdownClassName="blue-select">
                  <a-select-option value="usdt">
                    USDT
                  </a-select-option>
                </a-select>
              </a-input>
              <div class="input-error-tip bold"
                v-show="input.priceFormatError || input.allowMaxPriceError || input.decimalsError">
                <a-icon class="icon" type="close-circle" theme="filled" />
                <span v-show="input.priceFormatError">Wrong price format, please re-enter.</span>
                <span v-show="input.decimalsError">Precision cannot exceed 18 decimal places for price.</span>
                <span v-show="input.allowMaxPriceError">Can only be 10,000,000,000 USDT for max biding.</span>
              </div>
            </div>
            <div class="row" style="height: 80px;">
              <div class="label bold f16">Duration</div>
              <div class="content">
                <a-tooltip>
                  <template slot="title">
                    <span>Duration of sale</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </div>
            </div>
            <div style="margin-bottom: 32px; position: relative;">
              <a-input v-model.trim="durationInput.value" size="large" :disabled="action.loading" placeholder="Enter the duration quantity">
              <a-select slot="addonAfter" default-value="Days" style="width: 100px" size="large" @change="durationHandlerChange" dropdownClassName="blue-select">
                <a-select-option value="Days">
                  Days
                </a-select-option>
                <a-select-option value="Weeks">
                  Weeks
                </a-select-option>
                <a-select-option value="Months">
                  Months
                </a-select-option>
              </a-select>
            </a-input>
              <div class="input-error-tip bold"
                v-show="durationInput.formatError || durationInput.allowMaxIntError">
                <a-icon class="icon" type="close-circle" theme="filled" />
                <span v-show="durationInput.formatError">Invalid positive integer, please re-enter.</span>
                <span v-show="durationInput.allowMaxIntError">The maximum allowed positive integer is 1,000.</span>
              </div>
            </div>
            <div class="row" style="height: 80px;">
              <div class="label bold f16">Taker</div>
              <div class="content">
                <a-tooltip>
                  <template slot="title">
                    <span>Address of taker</span>
                  </template>
                  <a-icon type="exclamation-circle" />
                </a-tooltip>
              </div>
            </div>
            <div style="margin-bottom: 32px; position: relative;">
              <a-input v-model.trim="takerAddr" size="large" :disabled="action.loading" placeholder="Enter the taker address <Optional>">
              </a-input>
              <div class="input-error-tip bold" v-show="(takerAddr && !isAddress) || takerAsMaker">
                <a-icon class="icon" type="close-circle" theme="filled" />
                <span v-show="takerAddr && !isAddress">Invalid address, please check and try again.</span>
                <span v-show="takerAsMaker">Taker cannot be the owner of current DID, please re-enter.</span>
              </div>
            </div>
            <div class="options">
              <div class="option-content">
                <div class="row" style="height: 32px">
                  <div class="label bold">Fees</div>
                  <div class="content">
                    <a-tooltip>
                      <template slot="title">
                        Pay fees
                      </template>
                      <a-icon type="exclamation-circle"/>
                    </a-tooltip>
                  </div>
                </div>
                <div class="row" style="height: 32px">
                  <div class="label">Service Fee</div>
                  <div class="content">0.2%</div>
                </div>
              </div>
            </div>
          </template>
          <template v-if="!currentNavMenuItem.isOrder">
            <div class="buy-btn-icon" >
              <a-button
                v-if = "action.type === 'make'"
                class="buy-btn"
                @click="viewTxDetails"
                type="primary"
                icon="eye">
                View Details
              </a-button>
              <a-button
                class="buy-btn"
                v-else
                :class="{disabled: disabled}"
                icon="credit-card"
                type="primary"
                @click="sellDID"
              >
                Sell Now
              </a-button>
            </div>
          </template>
          <template v-else>
            <div class="a-button-tip" :style="{'margin-top': marginTop}">
              <a-button
                v-if="action.type === 'cancel'"
                class="cancel-btn"
                @click="viewTxDetails"
                type="primary"
                icon="eye"
              >
                View Details
              </a-button>
              <a-button
                v-else
                class="cancel-btn"
                @click="cancelFixedPriceOrder"
                :class="{disabled: action.loading}"
                type="primary"
              >
                Cancel Order
              </a-button>
              <template v-if="action.type !== 'cancel'">
                <a-button
                v-if="action.type === 'modify'"
                  class="modify-btn"
                  @click="viewTxDetails"
                  type="primary"
                  icon="eye"
                >
                View Details
                </a-button>
                <a-button
                  v-else
                  class="modify-btn"
                  :class="{disabled: disabled}"
                  @click="modifyFixedPriceOrder"
                  type="primary"
                >
                  Modify Order
                </a-button>
              </template>
            </div>
          </template>
      </div>
      <div class="product-info">
        <div class="thumb">
          <!-- <img src="@/assets/sale.gif"> -->
          <div class="text">DAOG ID AVATAR</div>
        </div>
      </div>
    </div>
    <div class="step-container">
      <div class="step-item" :class="{active: action.step > 0}">
        <div class="prefix">
          <div class="check" v-if="action.step > 1">
            <a-icon type="check" />
          </div>
          <a-spin v-if="action.step === 1"/>
        </div>
        <div>
          {{
            currentNavMenuItem.isOrder ?
            !showSettingInput ? '1 Request to cancel' : '1 Request to modify' :
            '1 Request to sell'
          }}
        </div>
      </div>
      <a-icon type="right" />
      <div class="step-item" :class="{active: action.step > 1}">
        <div class="prefix">
          <div class="check" v-if="action.step > 2">
            <a-icon type="check" />
          </div>
          <a-spin v-if="action.step === 2"/>
        </div>
        <div>2 Wait 30 seconds</div>
      </div>
      <a-icon type="right" />
      <div class="step-item" :class="{active: action.step === 3}">
        <div class="prefix">
          <div class="check" v-if="action.step === 3">
            <a-icon type="check" />
          </div>
        </div>
        <div>
          {{
            currentNavMenuItem.isOrder ?
            !showSettingInput ? '3 Canceled order' : '3 Modified order' :
            '3 Published Order'
          }}
        </div>
      </div>
    </div>
  </div>
  <a-modal
    v-model="dialog.show"
    title="Order details"
    @ok="() => handleOk()"
    @cancel="() => handleCancel()"
    ok-text="Confirm"
    cancel-text="Cancel"
    centered
    wrapClassName="custom-confirm"
  >
    <div class="dialog-container">
      <div class="key">
        <div>Selling price:</div>
        <div>Expiration time:</div>
        <div>Taker address:</div>
      </div>
      <div class="value">
        <div>{{ priceByEther }}</div>
        <div>{{ getUTCFormattedDate(this.expirationSeconds) }}</div>
        <div >{{ showTaker }}</div>
      </div>
    </div>
  </a-modal>
</div>
</template>

<script lang="js">
import {
  errorHandler,
  copy,
  formatEther,
  getTxDetails,
  getDIDNodeHash,
  getUTCFormattedDate
} from '@/utils/func'
import { utils } from 'ethers'
import { mapGetters } from 'vuex'
import { isAddress } from 'web3-utils'

export default {
  name: 'DIDDEX',
  props: {
    currentNavMenuItem: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      changedNFT: false,
      showSettingInput: false,
      receipt: {
        txHash: null
      },
      action: {
        type: '',
        step: 0,
        receipt: {},
        loading: false
      },
      input: {
        value: '',
        timer: null,
        decimalsError: false,
        priceFormatError: false,
        allowMaxPriceError: false
      },
      durationUnit: 'Days',
      durationInput: {
        value: '',
        formatError: false,
        allowMaxIntError: false
      },
      expirationSeconds: null,
      takerAddr: '',
      priceByWei: null,
      priceByEther: null,
      dialog: {
        show: false
      }
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId']),

    zeroAddr () {
      return process.env.VUE_APP_ZERO_ADDR
    },

    orderTaker () {
      return this.currentNavMenuItem.taker === this.zeroAddr
        ? 'Not set' : this.currentNavMenuItem.taker
    },

    showTaker () {
      return this.takerAddr
        ? this.takerAddr : 'Not set'
    },

    marginTop () {
      return this.showSettingInput ? '50px' : '350px'
    },

    DIDFullName () {
      return this.currentNavMenuItem.name + this.currentNavMenuItem.suffix
    },

    currentPriceByEther () {
      return this.currentNavMenuItem.price
    },

    takerAsMaker () {
      if (this.takerAddr) {
        if (this.takerAddr.toLocaleLowerCase() === this.address.toLocaleLowerCase()) {
          return true
        }
      }
      return false
    },

    isAddress () {
      if (!this.takerAddr) return true
      return isAddress(this.takerAddr)
    },

    disabled () {
      return this.input.priceFormatError ||
      this.input.allowMaxPriceError ||
      this.input.decimalsError ||
      this.durationInput.formatError ||
      this.durationInput.allowMaxIntError ||
      this.takerAsMaker ||
      !this.isAddress ||
      this.action.loading
    }
  },

  watch: {
    currentNavMenuItem: {
      handler (newVal, oldVal) {
        this.clearInputs()
        this.action.type = ''
        this.action.step = 0
        this.action.receipt = {}
        this.action.loading = false
        this.showSettingInput = false
        this.expirationSeconds = null
        this.searchOrder(newVal)
      },
      deep: true
    },

    'input.value': {
      handler (newVal, oldVal) {
        if (this.input.timer) {
          clearTimeout(this.input.timer)
        }
        this.input.timer = setTimeout(() => {
          this.processInputValue(newVal)
        }, 100)
      }
    },

    'durationInput.value': {
      handler (newVal, oldVal) {
        this.processDurationInput(newVal)
      }
    }
  },

  mounted () {
    this.searchOrder(this.currentNavMenuItem)
  },

  methods: {
    sellDID () {
      if (this.input.value === '0') {
        this.$message.info('Your selling price is 0, please re-enter.')
        return
      }

      if (!this.priceByWei) {
        this.$message.info('Please enter the price you want to sell.')
        return
      }

      if (!this.durationInput.value) {
        this.$message.info('Please enter the number of duration.')
        return
      }

      this.expirationSeconds = this.getExpirationSeconds(
        this.durationUnit, parseInt(this.durationInput.value)
      )
      this.action.loading = true
      this.dialog.show = true
    },

    handleOk () {
      this.dialog.show = false
      this.makeFixedPriceOrder()
    },

    handleCancel () {
      this.action.loading = false
      setTimeout(() => {
        this.changedNFT = false
      }, 500)
    },

    makeFixedPriceOrder () {
      this.action.step = 1
      const nodeHash = getDIDNodeHash(this.DIDFullName)

      const args = [
        nodeHash,
        this.takerAddr ? this.takerAddr : this.zeroAddr,
        this.expirationSeconds,
        this.priceByWei
      ]

      if (this.currentNavMenuItem.isOrder) {
        console.log('modifyFixedPriceOrder args:', args)
      } else {
        console.log('makeFixedPriceOrder args:', args)
      }

      this.$contracts.editor(true).makeFixedPriceOrder(...args)
        .then(result => {
          this.action.step = 2
          return result.wait()
        })
        .then(receipt => {
          this.action.receipt = receipt
          this.action.step = 3
          if (this.currentNavMenuItem.isOrder) {
            this.action.type = 'modify'
            this.$message.success('Order modified successfully !')
          } else {
            this.action.type = 'make'
            this.$message.success('Order published successfully !')
          }
          this.searchOrder(this.currentNavMenuItem)
        })
        .catch(err => {
          this.action.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.action.loading = false
        })
    },

    modifyFixedPriceOrder () {
      if (!this.showSettingInput) {
        this.showSettingInput = true
        return
      }

      this.sellDID()
    },

    cancelFixedPriceOrder () {
      this.showSettingInput = false
      this.action.loading = true
      this.action.step = 1
      const nodeHash = getDIDNodeHash(this.DIDFullName)
      console.log('cancelFixedOrder args:', nodeHash, false)

      this.$contracts.editor(true).cancelFixedOrder(nodeHash, false)
        .then(result => {
          this.action.step = 2
          return result.wait()
        })
        .then(receipt => {
          this.action.receipt = receipt
          this.action.step = 3
          this.action.type = 'cancel'
          this.searchOrder(this.currentNavMenuItem)
          this.$message.success('Order canceled successfully !')
        })
        .catch(err => {
          this.action.step = 0
          errorHandler(err)
        })
        .finally(() => {
          this.action.loading = false
        })
    },

    getExpirationSeconds (durationUnit, durationNum) {
      const startTimeSeconds = Math.round(Date.now() / 1000)
      const oneDaySeconds = 24 * 60 * 60

      switch (durationUnit) {
        case 'Days':
          return startTimeSeconds + durationNum * oneDaySeconds
        case 'Weeks':
          return startTimeSeconds + durationNum * 7 * oneDaySeconds
        case 'Months':
          return startTimeSeconds + durationNum * 30 * oneDaySeconds
      }
    },

    copyDomainName () {
      if (!this.currentNavMenuItem.name) {
        return
      }
      const data = this.DIDFullName
      copy(data)
    },

    durationHandlerChange (val) {
      this.durationUnit = val
    },

    searchOrder (item, index) {
      const functionSignature = 'searchOrder(bytes32)'
      const nodeHash = getDIDNodeHash(item.name + item.suffix)

      this.$contracts.multiCall()[functionSignature](nodeHash)
        .then(orderInfo => {
          if (orderInfo.expire.toNumber() > 0) {
            item.isOrder = true
          } else {
            item.isOrder = false
          }

          item.market = orderInfo.market
          item.taker = orderInfo.taker
          item.expire = orderInfo.expire.toNumber()
          item.price = orderInfo.price.isZero() ? 0 : formatEther(orderInfo.price)

          if (index === 0) {
            this.chooseSideItem(index)
          }

          console.log(`${item.name + item.suffix} orderInfo: ${orderInfo}`)
        }).catch(err => {
          console.log(err)
        })
    },

    getUTCFormattedDate (expirationSeconds) {
      return getUTCFormattedDate(expirationSeconds)
    },

    processInputValue (val) {
      try {
        this.input.priceFormatError = false
        this.input.allowMaxPriceError = false
        this.input.decimalsError = false

        if (!val) {
          this.priceByEther = 0
          return
        }

        const vals = val.split('.')
        const maxAllowPrice = 1e10

        if (vals.length === 1 && Number(vals[0]) > maxAllowPrice) {
          this.input.allowMaxPriceError = true
          return
        }

        if (vals.length === 2) {
          if (Number(vals[0]) >= maxAllowPrice) {
            this.input.allowMaxPriceError = true
            return
          }
        }

        this.priceByWei = utils.parseEther(val).toString()
        this.priceByEther = this.input.value

        console.log('Order price:', this.priceByWei)
      } catch (err) {
        switch (err.code) {
          case 'INVALID_ARGUMENT':
            this.input.priceFormatError = true
            break
          case 'NUMERIC_FAULT':
            this.input.decimalsError = true
            break
          default :
            this.input.priceFormatError = true
            console.log(err.code)
            break
        }
      }
    },

    processDurationInput (val) {
      this.durationInput.formatError = false
      this.durationInput.allowMaxIntError = false
      if (!val) return
      const regExp = /^[1-9]\d*$/g
      if (!regExp.test(val)) {
        this.durationInput.formatError = true
        return
      }
      const maxInt = 1e3
      if (parseInt(val) > maxInt) {
        this.durationInput.allowMaxIntError = true
      }
    },

    clearInputs () {
      this.takerAddr = ''
      this.input.value = ''
      this.durationInput.value = ''
    },

    viewTxDetails () {
      getTxDetails(this.chainId, this.action.receipt.transactionHash)
    }
  }
}
</script>

<style lang="scss" scoped>
.f16{
  font-size: 16px;
}

.bold {
  font-weight: 500;
}

::v-deep {
  .ant-modal-content {
    width: 550px;
  }
}
.dialog-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  text-align: left;
  overflow-y: auto;
  .key {
    font-weight: 500;
    white-space: nowrap;
  }
  .value {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    div {
      color: $valueColor;
      font-weight: 300;
      white-space: nowrap;

       &:first-child {
        color: red;
        font-weight: 400;

        &::after {
          content: 'MATIC';
          margin-left: 8px;
          font-style: italic;
        }
      }
    }
  }
}
.did-dex {
  .did-dex-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    // justify-content: space-between;
    // text-align: left;
    .buy-btn-icon {
      .buy-btn {
        width: 180px;
        height: 42px;
        border-radius: 8px;
        font-size: 16px;
        &:hover {
          opacity: 0.7;
        }
        .tip-text {
          overflow: auto;
          overflow-wrap: break-word;
        }
      }
      .tip-icon {
        margin-left: 16px;
        color: #F5222D;
        font-size: 30px;
      }
    }

    ::v-deep {
      .ant-input-group{
        .ant-select-selection{
          &:hover{
            border-color: transparent;
          }
          &:focus{
            border-color: transparent;
            box-shadow: none;
          }
          .ant-select-selection-selected-value {
            color: #555;
          }
        }
        .ant-select-open .ant-select-selection,
        .ant-select-focused .ant-select-selection{
          border-color: transparent;
          box-shadow: none;
        }
      }
      .input-error-tip {
        position: absolute;
        top: 42px;
        left: 3px;
        color: rgba($color: red, $alpha: 0.9);
        .icon {
          margin-right: 6px;
          padding-bottom: 2px;
          color: rgba($color: red, $alpha: 1);
          vertical-align: middle;
        }
      }
    }
    .content-container{
      display: flex;
      justify-content: space-between;
      .info{
        width: 468px;
        overflow: hidden;
        .row{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .domain-name {
            font-size: 14px;
            color: $valueColor;
          }
          .copy-icon {
            &:active {
              color: #b15be6;
            }
          }
          .label{
            flex: 1;
          }
          .content {
            margin-left: 12px;
            font-size: 14px;
            word-break: break-all;

            div {
              color: $valueColor;
            }
            .price {
              color: red;
              font-size: 18px;
              font-weight: bold;
              .symbol {
                font-style: italic;
              }
              .usdt-token {
                width: 20px;
                margin-left: 10px;
                margin-bottom: 4px;
              }
            }
          }
        }
        .a-button-tip {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .cancel-btn, .modify-btn {
            padding-top: 4px;
            width: 180px;
            height: 42px;
            border-radius: 8px;
            font-size: 16px;

            &:hover {
              opacity: 0.7;
            }
          }
          .cancel-tip {
            margin-top: 24px;
            color: $valueColor;
            font-size: 16px;
            .tip-text {
              margin-left: 8px;
            }
          }
        }
      }
      .options{
        margin-bottom: 24px;
        .bar{
          height: 32px;
          border-bottom: 1px solid #EDEEFF;
        }
        .option-content{
          padding: 16px 0;
        }
      }
      .product-info{
        .thumb{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 40px;
          width: 320px;
          height: 320px;
          border-radius: 16px;
          background: #fce9da;
          background: rgba(58, 78, 255, 0.06);
          .text {
            font-size: 24px;
            font-weight: 900;
            color: #fff;
            text-shadow: 0 0 6px $blueOth;
            user-select: none;
            user-select: none;
            transition: all 0.3s;
          }
        }
      }
    }
    .step-container{
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      margin-top: 24px;
      width: 900px;
      height: 42px;
      border-radius: 8px;
      background: $bgColor;
      .step-item{
        width: 280px;
        padding: 0 32px;
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: #999;
        &.active{
          color: #333;
        }
        .prefix{
          width: 26px;
          height: 26px;
          margin-right: 16px;
          display: inline-flex;
          align-items: center;
          line-height: 1;
          .check{
            width: 26px;
            height: 26px;
            padding-left: 6px;
            border-radius: 26px;
            line-height: 30px;
            color: $blue;
            background: rgba($color: $blue, $alpha: 0.3);
          }
          .ant-spin{
            line-height: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobileWidth){
    .did-dex-container{
      flex-direction: column;
      min-height: 300px;
      margin: 0;
    }
    .content-container{
      flex-direction: column;
      align-items: center;

        .info{
          width: 100% !important;
          margin-right: 0;
          .row {
            .content {
              .price {
                font-size: 16px;
              }
            }
          }
          .a-button-tip {
            flex-direction: column;
            margin-top: 50px !important;
            .modify-btn {
              margin-top: 24px;
            }
          }
        }
        .product-info{
          margin: 24px 0;
          .thumb{
            width: 180px;
            height: 180px;
            margin-left: unset !important;
            .text {
              font-size: 16px;
            }
          }
        }
      }
    .buy-btn-icon{
      text-align: center;
    }

    .step-container{
      flex-direction: column;
      width: 100% !important;
      height: auto !important;
      padding: 16px 0;
      margin-top: unset !important;
      .step-item{

      }
      .anticon-right{
        transform: rotate(90deg);
        margin: 8px 0;
      }
    }
  }
}
</style>

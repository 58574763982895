<template>
  <teleport to="#backdrop-root">
      <div class="backdrop" @touchmove.prevent>
        <slot/>
      </div>
  </teleport>
</template>

<script>
import teleport from '@/components/teleport.vue'
export default {
  name: 'backdrop',
  components: {
    teleport
  }
}
</script>

<style lang="scss" scoped>
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1000;
 }
</style>

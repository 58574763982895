<template>
  <div class="home">
    <pageHeader></pageHeader>
    <div class="body-container">
      <template>
        <tabs v-model="tab.active" @change="changeTab"></tabs>
        <template v-if="tab.active === 0">
          <div class="search-container">
            <domain-search
              @search="handleSearchResult" :fullName="fullName">
            </domain-search>
          </div>
          <div class="search-result-container" v-if="showSearchResult && !beforeLoading">
            <div class="top-bar">
              <div class="top-bar-left">
                <div class="invalid" v-if="isInvalid"> {{ $t('registerTag.invalid') }}</div>
                <div class="available" v-else-if="isAvailable"> {{ $t('registerTag.available') }} </div>
                <div class="available" v-else-if="isReselling"> {{ $t('registerTag.reselling') }} </div>
                <div class="available" v-else-if="isRegistered"> {{ $t('registerTag.registered') }} </div>
              </div>
              <div class="error" v-if="isInvalid">
                <a-icon class="error-icon" type="close-circle" theme="filled"/>
                {{ invalidTip }}
              </div>
              <div class="top-bar-right">
                <div class="button-loading" v-if="chainId === supportChainId && (isAvailable || isReselling)">
                  <div class="primary-btn" @click="handlePrimaryClick" :class="{disabled: register.loading}">
                    {{ register.step === 3 ? $t('primaryBtn.viewDetails') : $t('primaryBtn.register') }}
                  </div>
                </div>
                <div class="button-loading" v-else>
                  <div class="primary-btn" :class="{disabled: true}">
                      {{ $t('primaryBtn.register') }}
                  </div>
                </div>
              </div>
            </div>
            <template>
              <div class="search-result-content">
                <div class="content-body">
                  <div class="step-container" :class="{disabled: isInvalid || isRegistered}">
                    <div class="step-item" :class="{active: register.step > 0}">
                      <div class="prefix">
                        <div v-if="!isAvailable && !isReselling" class="close">
                          <a-icon type="close" />
                        </div>
                        <div v-else-if="register.step > 1" class="check">
                          <a-icon type="check" />
                        </div>
                        <div v-if="register.step === 1">
                          <a-spin/>
                        </div>
                      </div>
                      <div>1 {{ $t('registerStep.request') }}</div>
                    </div>
                    <a-icon type="right" />
                    <div class="step-item" :class="{active: register.step > 1}">
                      <div class="prefix">
                        <div class="check" v-if="register.step > 2">
                          <a-icon type="check" />
                        </div>
                        <a-spin v-if="register.step === 2"/>
                      </div>
                      <div>2 {{ $t('registerStep.wait') }}</div>
                    </div>
                    <a-icon type="right" />
                    <div class="step-item" :class="{active: register.step > 2}">
                      <div class="prefix">
                        <div class="check" v-if="register.step === 3">
                          <a-icon type="check" />
                        </div>
                      </div>
                      <div>3 {{ $t('registerStep.complete') }}</div>
                    </div>
                  </div>
                  <div class="did-name">
                    <div v-if="isInvalid" class="invalid-name"> {{ searchResult.fullName }}</div>
                    <div v-else class="did-name-value" >{{ searchResult.fullName }}</div>
                  </div>
                  <div class="price-container">
                    <div class="label" @click="getAddrDetails(DIDInfo.owner)">
                      <span class="key">Owner:</span>
                      <span class="value">{{ DIDInfo.owner }}</span>
                    </div>
                    <div class="price">
                      <span>{{ DIDInfo.price }}</span>
                      <span class="symbol">USDT</span>
                      <img class="usdt-token" src="@/assets/network/usdt.png">
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="lists">
              <div class="list-container">
                <a-tooltip placement="top">
                  <!-- <span slot="title">Add or remove</span> -->
                  <div class="btn" @click="ListHandler('register')" :class="{disabled: disableRegister}">
                   Add to Cart
                  <a-icon v-if="inRegisteredList" type="shopping-cart" style="color: #E1251B; font-size: 22px;"/>
                  <a-icon v-else type="shopping-cart" style="color: #666; font-size: 22px;"/>
                    {{ registeredAmount }}
                  </div>
                </a-tooltip>
                <div class="dids">
                  <div
                    class="item"
                    :class="{'line-through': errorList.name === 'register' && errorList.index === index}"
                    v-for="(item, index) in registeredList" :key="index"
                    @click="getActiveIndex('register', index)"
                  >
                    {{ item + '.e' }}
                    <a-tooltip placement="top">
                      <img class="delete-icon"
                        v-show="registeredListActiveIndex === index"
                        @click="deleteItemHandler('register')"
                        src="@/assets/registration/delete.png"
                      >
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="button-container" v-if="chainId === supportChainId && (isAvailable || isReselling)">
              <div class="primary-btn" @click="handlePrimaryClick" :class="{disabled: register.loading}">
                {{ register.step === 3 ? $t('primaryBtn.viewDetails') : $t('primaryBtn.register') }}
              </div>
            </div>
            <div class="button-container" v-else>
              <div class="primary-btn" :class="{disabled: true}">
                {{ $t('primaryBtn.register') }}
              </div>
            </div>
          </div>
          <div class="search-failed" v-else-if="searchFailed">
            <div class="">
              <span class="text">⚠️ Search failed, please try again.</span>
            </div>
          </div>
          <div class="before-search-result-container" v-show="beforeLoading && !showSearchResult">
            <div class="before-loading">
              <a-spin tip="Loading..." size="large" :style="{color:'#333'}"/>
            </div>
          </div>
        </template>
        <template v-if="tab.active === 1">
          <!-- mydomain -->
          <div class="mydomain">
            <my-domain @changeTab="changeTabHandler"></my-domain>
          </div>
        </template>
      </template>
    </div>
    <pageFooter></pageFooter>
    <a-modal
      v-model="dialog.show"
      title="Fee Details"
      @ok="() => handleOk()"
      @cancel="() => handleCancel()"
      ok-text="Confirm"
      cancel-text="Cancel"
      :confirm-loading="isApproving"
      centered
      wrapClassName="custom-confirm"
    >
      <div class="dialog-container">
        <div class="fee-name">
          <div class="name">Registers</div>
          <div class="name">Register Orders</div>
          <div class="name">Total expenses</div>
        </div>
        <div class="price">
          <div>{{ cost.registers }}</div>
          <div>{{ cost.orders }}</div>
          <div>{{ cost.total }}</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import domainSearch from '@/components/domainSearch'
import pageHeader from '@/components/layout/pageHeader'
import pageFooter from '@/components/layout/pageFooter'
import tabs from '@/components/tabs'
import myDomain from '@/components/myDomain.vue'
import { mapGetters, mapActions } from 'vuex'

import {
  formatEther,
  getTxDetails,
  getAddrDetails,
  errorHandler,
  createWalletConnect
} from '@/utils/func'

const zeroAddr = process.env.VUE_APP_ZERO_ADDR

export default {
  name: 'Registration',
  components: {
    pageHeader,
    pageFooter,
    domainSearch,
    tabs,
    myDomain
  },

  data () {
    return {
      showImgText: false,
      showSearchResult: false,
      searchFailed: false,
      isInvalid: false,
      isApproving: false,
      invalidTip: '',
      fullName: {},
      beforeLoading: false,
      errorList: {
        name: '',
        index: null
      },
      dialog: {
        show: false
      },
      searchResult: {
        // loading: false,
        alias: '',
        suffix: '',
        fullName: ''
      },
      tab: {
        active: 0,
        listPath: [
          '/dapp',
          '/names'
        ]
      },
      register: {
        step: 0,
        loading: false,
        receipt: {}
      },

      cost: {
        total: '',
        totalWei: '',
        orders: '',
        registers: ''
      },

      DIDInfo: {
        askerNode: '',
        targetNode: '',
        price: 0,
        owner: '',
        taker: ''
      },
      newList: [],
      resaleList: [],
      registeredList: [],
      registeredListActiveIndex: null
    }
  },

  computed: {
    ...mapGetters(['address', 'chainId', 'reverseFullName']),

    supportChainId () {
      return process.env.VUE_APP_SUPPORT_CHAIN_ID
    },

    spenderAddr () {
      return process.env.VUE_APP_REGISTRAR_ADDR
    },

    isEmpty () {
      return this.registeredList.length === 0
    },

    isOrderHolder () {
      return this.DIDInfo.owner.toLocaleLowerCase() === this.address.toLocaleLowerCase()
    },

    isTaker () {
      return this.DIDInfo.taker.toLocaleLowerCase() === this.address.toLocaleLowerCase()
    },

    isDesignatedTaker () {
      return this.DIDInfo.taker && this.DIDInfo.taker !== zeroAddr
    },

    disableRegister () {
      return this.isInvalid ||
        this.isRegistered ||
        this.register.step > 0
    },

    // state [崭新的; 转卖中; 已注册; 无效]
    isAvailable () {
      // Owner 为零地址并且价格存在(大于0)
      return this.DIDInfo.owner === zeroAddr && this.DIDInfo.price > 0
    },
    isReselling () {
      // Owner 不为零地址并且价格存在（大于0）
      return this.DIDInfo.owner !== zeroAddr && this.DIDInfo.price > 0
    },

    isRegistered () {
      // Owner 不为零地址并且价格等于0
      return this.DIDInfo.owner !== zeroAddr && this.DIDInfo.price === 0
    },

    inRegisteredList () {
      return this.registeredList.includes(this.searchResult.alias)
    },

    registeredAmount () {
      return this.registeredList.length
    }
  },

  created () {
    if (this.$route.fullPath === '/') {
      this.$router.replace('/dapp')
    }
    const tabPathIndex = this.tab.listPath.findIndex(item => item === this.$route.path)
    this.tab.active = tabPathIndex !== -1 ? tabPathIndex : 0
    this.getRouteParams()
    this.initFunc()
    // window.addEventListener('beforeunload', this.beforeunloadHandler)
  },

  beforeDestroy () {
    if (window.walletConnectWeb3Provider) {
      window.walletConnectWeb3Provider.removeAllListeners()
    } else if (window.ethereum) {
      window.ethereum.removeAllListeners()
    }
    // window.removeEventListener('beforeunload', this.beforeunloadHandler)
  },

  methods: {
    ...mapActions([
      'setLogin',
      'setAddress',
      'setChainId',
      'setReverseFullName'
    ]),

    changeTab (index) {
      this.tab.active = index
      this.$router.replace(this.tab.listPath[index])
    },

    changeTabHandler (obj) {
      this.tab.active = obj.active
    },

    goDocsPage () {
      window.open('https://diddao.gitbook.io/docs')
    },

    getReverseFullName (addr) {
      if (this.reverseFullName) return

      console.log('Reverse arg:', addr)

      this.$contracts.resolver().reverse(addr).then(res => {
        console.log('Reverse:', res)
        this.setReverseFullName(res[1])
      }).catch(() => {
        // console.log(err)
      })
    },

    getAddrDetails (addr) {
      getAddrDetails(this.chainId, addr)
    },

    async handlePrimaryClick () {
      if (this.register.step === 0) {
        if (this.isEmpty) {
          this.$message.info('No DID Names will be processed !')
          return
        }

        this.errorList.name = ''
        this.errorList.index = null

        this.register.step = 1

        this.launchToChain()
      } else if (this.register.step === 3) {
        getTxDetails(this.chainId, this.register.receipt.transactionHash)
      }
    },

    async handleSearchResult (obj) {
      this.beforeLoading = obj.beforeLoading
      this.showSearchResult = false
      this.isApproving = false
      this.isInvalid = false
      this.invalidTip = ''
      this.searchFailed = false
      this.registeredListActiveIndex = null
      this.emptyTxData()
      this.DIDInfo = {}
      this.searchResult = {}

      this.searchResult = this.$options.data().searchResult
      this.searchResult = { ...obj }

      await this.searchFromChain(this.searchResult.alias)

      if (!this.searchFailed) {
        this.showSearchResult = true
      }

      this.beforeLoading = false

      // DID name 无效
      if (this.isInvalid) {
        const length = obj.alias.length
        if (length < 3) {
          this.invalidTip = 'DID name cannot be less than 3 characters.'
          return
        }
        if (length > 32) {
          const msg = 'DID name cannot be longer than 32 characters.'
          this.invalidTip = msg
          this.searchResult.fullName = msg
          return
        }
        this.invalidTip = 'Illegal characters < Note: can only contain 0~9, -, a~z >.'
      }
    },

    handleCancel () {
      this.register.step = 0
      this.register.loading = false
    },

    async handleOk () {
      this.isApproving = true
      let args

      try {
        args = [
          this.address,
          this.spenderAddr
        ]

        console.log('allowance args:', args)
        const allowance = await this.$contracts.usdt().allowance(...args)
        console.log('allowance:', allowance)

        if (allowance.lt(this.cost.totalWei)) {
          args = [
            this.spenderAddr,
            this.cost.totalWei
          ]

          console.log('approve args:', args)
          const approveTx = await this.$contracts.usdt(true).approve(...args)
          const res = await approveTx.wait()
          console.log('approve:', res)
        }
      } catch (err) {
        this.isApproving = false
        errorHandler(err)
        return
      }

      const orders = []

      if (this.resaleList.length > 0) {
        this.resaleList.forEach((item) => {
          orders.push(item.nodeHash)
        })
      }

      args = [
        0,
        orders,
        this.newList,
        this.$agent ? this.$agent : zeroAddr
      ]

      console.log('launchToChain args: ', args)

      this.$contracts.multiCall(true).launchToChain(...args)
        .then(result => {
          this.register.step = 2
          this.dialog.show = false
          return result.wait()
        })
        .then(receipt => {
          this.register.step = 3
          this.register.receipt = receipt
          if (this.registeredList.length > 0) {
            this.getReverseFullName(this.address)
          }
          this.emptyList()
          this.$message.success('Transaction executed successfully !')
        })
        .catch(err => {
          this.launchCheck()
          this.register.step = 0
          if (err.code === 'INVALID_ARGUMENT') {
            this.$message.error('Agent address error, please check and try again.')
          }
          errorHandler(err)
        })
        .finally(() => {
          this.isApproving = false
          this.register.loading = false
        })
    },

    async searchFromChain (targetName) {
      const args = [
        0,
        targetName
      ]

      console.log('search args: ', args)

      try {
        const info = await this.$contracts.multiCall().search(...args)
        this.DIDInfo.targetNode = info.targetNode
        this.DIDInfo.price = info.price.isZero() ? 0 : formatEther(info.price)
        this.DIDInfo.owner = info.owner
        this.DIDInfo.taker = info.taker

        console.log('Info: ', info)
        console.log('DIDInfo:', JSON.stringify(this.DIDInfo))
      } catch (err) {
        // RPC网络或域名无效
        if (err.reason === 'Invalid name') {
          this.isInvalid = true
          console.log('err', err.reason, err.code)
        } else {
          this.searchFailed = true
          this.$message.error('Network error, query failed.')
        }
        console.log(err)
      }
    },

    async launchToChain () {
      const { state } = await this.launchCheck()

      if (state === 0) {
        this.dialog.show = true
      } else {
        this.handleCancel()
      }
    },

    async launchCheck () {
      this.register.loading = true
      const orders = []
      const errBaseCode = 1e9

      if (this.resaleList.length > 0) {
        this.resaleList.forEach((item) => {
          orders.push(item.nodeHash)
        })
      }

      const args = [
        0,
        orders,
        this.newList
      ]

      console.log('launchCheck args: ', args)

      try {
        const res = await this.$contracts.multiCall().launchCheck(...args)
        console.log('launchCheck:', res)

        const state = res.state.toNumber()
        this.cost.orders = res.costs[0].isZero() ? 0 : formatEther(res.costs[0])
        this.cost.registers = res.costs[1].isZero() ? 0 : formatEther(res.costs[1])
        this.cost.totalWei = res.costs[0].add(res.costs[1])
        this.cost.total = this.cost.totalWei.isZero() ? 0 : formatEther(this.cost.totalWei)
        console.log('Cost totalWei:', this.cost.totalWei)

        if (state === 0) {
          console.log('State right:', state)
          return { state }
        }

        if (state >= 2 * errBaseCode && state < 3 * errBaseCode) {
          this.getErrorList(state - 2 * errBaseCode, 'order')
          this.$message.error(`An error occurred in the ${this.errorList.index + 1}th DID in the cart list. Please delete it and try again.`)
          console.log('Resale orders error:', state, state - 2 * errBaseCode)
        } else if (state >= 3 * errBaseCode && state < 4 * errBaseCode) {
          this.getErrorList(state - 3 * errBaseCode, 'register')
          this.$message.error(`An error occurred in the ${this.errorList.index + 1}th DID in the cart list. Please delete it and try again.`)
          console.log('Registers error with index:', state, state - 3 * errBaseCode)
        }
        this.handleCancel()
        return {}
      } catch (err) {
        this.handleCancel()
        errorHandler(err)
      }
    },

    deleteItemHandler (flagStr) {
      switch (flagStr) {
        case 'register': {
          const index = this.resaleList.findIndex(obj =>
            obj.alias === this.registeredList[this.registeredListActiveIndex]
          )
          const index1 = this.newList.indexOf(this.registeredList[this.registeredListActiveIndex])
          if (index !== -1) {
            this.resaleList.splice(index, 1)
          } else if (index1 !== -1) {
            this.newList.splice(index1, 1)
          }
          this.registeredList.splice(this.registeredListActiveIndex, 1)
          break
        }
      }
      this.clearListError(flagStr)
    },

    ListHandler (flagStr) {
      let index, arr

      switch (flagStr) {
        case 'register':
          index = this.registeredList.indexOf(this.searchResult.alias)
          arr = this.registeredList

          if (this.isOrderHolder || (this.isDesignatedTaker && !this.isTaker)) break

          if (index === -1) {
            if (this.isReselling) {
              this.resaleList.push({
                alias: this.searchResult.alias,
                nodeHash: this.DIDInfo.targetNode
              })
            } else if (this.isAvailable) this.newList.push(this.searchResult.alias)
          } else {
            if (this.isReselling) this.resaleList.splice(index, 1)
            else if (this.isAvailable) this.newList.splice(index, 1)
          }
          break
      }

      // TODO
      if (index === -1) {
        if (flagStr === 'register') {
          if (this.isOrderHolder) {
            this.$message.info('You already own this DID and are currently selling it.')
            return
          }

          if (this.isDesignatedTaker && !this.isTaker) {
            this.$message.info('This DID order has already specified a buyer, you cannot purchase it.')
            return
          }
        }

        arr.push(this.searchResult.alias)
      } else {
        arr.splice(index, 1)
        this.clearListError(flagStr)
      }
    },

    emptyTxData () {
      this.register.step = 0
      this.register.loading = false
      this.register.receipt = {}
    },

    emptyList () {
      this.newList.length = 0
      this.resaleList.length = 0
      this.registeredList.length = 0
    },

    getActiveIndex (flagStr, index) {
      switch (flagStr) {
        case 'register':
          this.registeredListActiveIndex = index
          break
      }
    },

    getErrorList (index, listName) {
      switch (listName) {
        case 'register':
          this.errorList.name = listName
          this.errorList.index = this.registeredList.indexOf(this.newList[index])
          break
        case 'order':
          this.errorList.name = 'register'
          this.errorList.index = this.registeredList.indexOf(this.resaleList[index].alias)
          break
      }
    },

    clearListError (listName) {
      switch (listName) {
        case 'register':
          if (this.errorList.name === listName) {
            if (this.registeredListActiveIndex === this.errorList.index) {
              this.errorList.index = null
              this.errorList.name = ''
            } else {
              this.trackErrorIndex(this.registeredListActiveIndex)
            }
          }
          break
      }
    },

    trackErrorIndex (delIndex) {
      if (this.errorList.index && this.errorList.index > delIndex) {
        this.$set(this.errorList, 'index', this.errorList.index - 1)
      }
    },

    getRouteParams () {
      // 通过路由获取参数
      console.log('searchFullName === ', this.$route.query?.name)

      if (this.$route.query?.name) {
        const fullName = this.$route.query.name
        this.fullName.name = fullName.split('.')[0]
        this.fullName.suffix = '.e'
      }

      if (this.$route.params?.suffix) {
        this.fullName.suffix = this.$route.params.suffix
      }
    },

    // async beforeunloadHandler (event) {
    //   if (!window.localStorage.getItem('walletconnect')) {
    //     try {
    //       const accounts = await window.ethereum.request({
    //         method: 'eth_accounts'
    //       })
    //       this.setAddress(accounts[0])
    //     } catch (err) {
    //       console.err(err)
    //     }

    //     if (this.address) {
    //       window.localStorage.setItem('user', JSON.stringify({
    //         address: this.address
    //       }))
    //     }
    //   }
    // },

    async initFunc () {
      // TODO
      if (window.localStorage.getItem('walletconnect')) {
        const provider = await createWalletConnect()

        if (window.walletConnectWeb3Provider && provider) {
          // Listen to EIP-1193 events to detect accounts and chain change and also disconnection.
          // Subscribe to accounts change
          window.walletConnectWeb3Provider.on('accountsChanged:', (accounts) => {
            console.log('accountsChanged:', accounts)
            this.setAddress(accounts[0])
          })

          // Subscribe to chainId change
          window.walletConnectWeb3Provider.on('chainChanged', (chainId) => {
            console.log('chainChanged:', chainId)
            this.setChainId('0x' + chainId.toString(16))
          })

          // Subscribe to session connection
          window.walletConnectWeb3Provider.on('connect', () => {
            console.log('connect')
          })

          // Subscribe to session disconnection, default cached provider is cleared,
          window.walletConnectWeb3Provider.on('disconnect', (code, reason) => {
            console.log(code, reason)
            delete window.walletConnectWeb3Provider
            this.setLogin(false)
            this.setAddress('')
            this.setChainId('')
            window.location.reload()
          })

          try {
            const signer = await provider.getSigner()
            const chainId = '0x' + (await signer.getChainId()).toString(16)
            const address = await signer.getAddress()

            this.setLogin(true)
            this.setAddress(address)
            this.setChainId(chainId)

            console.log('Current signer:', address, 'chainId:', chainId)
          } catch (err) {
            console.log(err)
          }
        }
      } else if (window.ethereum) {
        window.ethereum.on('accountsChanged', this.handleAccountsChanged)
        window.ethereum.on('chainChanged', this.handleChainChanged)
        window.ethereum.on('disconnect', this.handleAccountsDisconnect)
      }
    },

    imgLoaded () {
      this.showImgText = true
    },

    handleAccountsDisconnect () {
      console.log('disconnect')
    },

    handleAccountsChanged (accounts) {
      console.log('accountChanged:', accounts)
      if (this.address) {
        window.location.reload()
      }
    },

    handleChainChanged (chainId) {
      this.setChainId(chainId)
      // window.location.reload()
    },

    handleRpcError (err) {
      console.log(err)
      this.$message.error('RPC network error occurred, Refresh the page and try again.')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-container{
  display: flex;
  width: 100%;
  height: 120px;
  text-align: left;
  overflow-y: auto;
  .fee-name {
    color: #555;
    .name:last-child {
      margin-top: 12px;
    }
  }
  .price {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    color: red;
    div {
      &:last-child {
        margin-top: 12px;
      }
      &::after {
        content: 'USDT';
        margin-left: 8px;
        font-style: italic;
      }

    }

  }
}
.home{
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-image: linear-gradient(#E9EAFF, #FBFBFF);
  overflow: auto;
  .body-container{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    .search-bar{
      width: 890px;
      height: 60px;
      border-radius: 60px;
      display: flex;
      background: #fff;
      border: 1px solid #CFD3F7;
    }
    .search-container {
      padding: 48px 0;
    }
    // test
    .windmill {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1200px;
      min-height: 450px;
      margin-bottom: 50px;
      .windmill-container {
        position: relative;
        width: 500px;
        .image-text {
          position: absolute;
          top: 164px;
          left: 207px;
          font-size: 20px;
          font-weight: 900;
          color: #fff;
          text-shadow: 0 0 6px $blueOth;
          user-select: none;
        }

      img {
        width: 100%;
        user-select: none;
        pointer-events: none;
        animation: mymove 80s infinite;
        animation-timing-function: linear;
        animation-delay: -2s;

        @keyframes mymove {
            from { transform: rotate(0deg) }
            to { transform: rotate(360deg) }
          }
        }
      }
    }
    .search-result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1200px;
      min-height: 660px;
      margin-bottom: 120px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0 0 6px rgba(88, 100, 255, 0.2);
      .before-loading {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .top-bar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 900px;
        height: 80px;
        border-bottom: 1px solid #edeeff;
        .error {
          padding: 0 16px;
          color: rgba(255, 0, 0, 0.9);
          font-weight: 500;
          &-icon {
            padding-right: 8px;
          }
        }

        &-left {
          width: 14%;
          text-align: center;
          .available, .invalid {
            padding: 6px 18px;
            color: $blue;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            border-radius: 4px;
            background: rgba($color: $blue, $alpha: 0.1);
          }
          .invalid {
            color: red;
          }
        }

        &-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        .button-loading {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .primary-btn {
            padding: 0 16px;
            line-height: 40px;
            border-radius: 40px;
            background: $mainBtnColor;
            color: #FFF;
            cursor: pointer;
            user-select: none;

            &:active {
              opacity: 0.6;
            }
          }
        }
      }
    }

      .search-result-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 60px auto;
        font-weight: 500;

        .unavailable-icon{
          margin-top: -80px;
        }
        .text-tip {
          font-size: 20px;
          font-weight: 400;
        }
      }

      .button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 900px;
        margin: 24px 0;
        .primary-btn {
          padding: 0 16px;
          line-height: 40px;
          border-radius: 40px;
          background: $mainBtnColor;
          color: #FFF;
          cursor: pointer;
          user-select: none;

          &:active {
            opacity: 0.6;
          }
        }
      }
      .lists {
        display: flex;
        width: 900px;
        height: 230px;

        .list-container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          width: 100%;
          height: 100%;
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
          .btn {
            padding: 4px 12px;
            font-size: 14px;
            border-radius: 4px;
            background-color: rgba(58, 78, 255, 0.1);
            cursor: pointer;
            user-select: none;

            &:active {
              opacity: 0.6;
            }
          }
          .dids {
            display: flex;
            width: 100%;
            height: 100%;
            padding: 2px 2px;
            margin-top: 24px;
            border-radius: 4px;
            box-shadow: 0 0 6px rgba(58, 78, 255, 0.2);
            border: 1px solid #ccc;
            flex-direction: column;
            overflow: auto;
            scrollbar-width: none;

            // chrome edge safari
            &::-webkit-scrollbar {
              display: none;
            }

            .agent {
              padding-left: 4px;
              margin-bottom: 8px;
              color: $blueOth;
              text-align: center;
              border-style: none;
              border-bottom: 1px solid #ddd;
              outline: none;

              &::placeholder {
                font-size: 12px;
                color: #666;
                opacity: 0.6;
              }
            }
            .item {
              position: relative;
              margin-bottom: 2px;
              padding-left: 4px;
              border-radius: 2px;
              color: $blueOth;
              font-size: 16px;
              height: 24px;
              line-height: 24px;
              text-align: left;
              cursor: pointer;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                color: #333;
                background-color: rgba(58, 78, 255, 0.1);
              }

              .delete-icon {
                position: absolute;
                right: 1px;
                top: 4px;
                width: 16px;
              }
            }
          }
        }
      }

      .content-body{
        .consume-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
          .item {
            font-size: 18px;
            font-weight: 600;
            color: red;
            cursor: pointer;
            .name {
              color: $valueColor;
              margin-right: 12px;
            }
          }
        }
        .did-name {
          max-width: 900px;
          margin: 80px auto;
          text-align: center;
          font-size: 24px;
          font-weight: 600;

          .did-name-value, .invalid-name {
            display: inline-flex;
            color: $blueOth;
            padding: 8px 32px;
            border-radius: 4px;
            box-shadow: 0px 0px 12px rgba(58, 78, 255, 0.3);

            word-wrap: break-word;
            overflow-wrap: break-word;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .invalid-name {
            color: #aaa;
            box-shadow: 0px 0px 12px #ccc
          }
        }

        .price-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          overflow: hidden;
          .price {
            font-size: 18px;
            font-weight: 600;
            color: red;
            text-align: right;
            .symbol {
              margin-left: 8px;
              font-weight: bold;
              font-style: italic;
            }
            .usdt-token {
              width: 24px;
              margin-left: 10px;
              margin-bottom: 5px;
            }
            &:before {
              content: 'Price';
              color: $valueColor;
              margin-right: 16px;
            }
          }
          .label {
            color: $blue;
            cursor: pointer;
            .value {
              margin-left: 6px;
              color: $valueColor;
            }

            &:last-child {
              border-right: none;
            }
          }
        }
        .step-container{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 900px;
          height: 42px;
          margin-top: 24px;
          border-radius: 24px;
          background-color: rgba(58, 78, 255, 0.1);
          line-height: 1;

          .step-item{
            display: flex;
            width: 280px;
            padding: 0 32px;
            color: #999999;
            font-size: 14px;
            font-weight: bold;
            align-items: center;

            &.active{
              color: #333333;
            }

            .prefix{
              width: 26px;
              height: 26px;
              margin-right: 16px;
              display: inline-flex;
              align-items: center;
              line-height: 1;

              .check, .close {
                width: 26px;
                height: 26px;
                border-radius: 26px;
                line-height: 30px;
                color: $blue;
                background: rgba($color: $blue, $alpha: 0.3);
              }
              .close {
                line-height: 28px;
                color: red;

                &:last-child {
                  font-size: 16px;
                }
              }

              .ant-spin{
                line-height: 1;
              }
            }
          }
        }
      }

      &.unavailable {
        .top-bar{
          color: #FF3333;
          .available{
            color: #FF3333;
            background: rgba($color: #FF3333, $alpha: 0.1);
          }
        }
      }
    }
    .before-search-result-container, .search-failed {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1200px;
      min-height: 660px;
      border-radius: 16px;
      margin-bottom: 240px;
      background: #fff;
      box-shadow: 0 0 6px rgba(88, 100, 255, 0.2);
      .before-loading {
        font-size: 100px;
      }
    }
    .search-failed {
      color: $blue;
      font-size: 16px;
    }
    .mydomain {
      width: 1200px;
      margin: 48px auto;
      min-height: 660px;
      flex: 1;
      // overflow: hidden;
      margin-bottom: 120px;
    }
  }

  @media screen and (max-width: $mobileWidth){
    overflow: hidden;
    .body-container{
      .search-container {
        padding: 48px 12px;
        width: 100%;
      }
      .before-search-result-container, .search-failed {
        width: calc(100% - 32px);
      }

      .search-result-container{
        width: calc(100% - 32px);
        margin: 0 16px;
        padding: 0 16px;

        .top-bar{
          width: 100%;
          flex-direction: column;
          height: auto;
          padding: 12px 0;

          .error {
            padding: 12px;
          }
        }

        .top-bar-left{
          width: auto;
        }

        .top-bar-right{
          display: none;
          flex-direction: column;
          width: 100%;
          .button-loading{
            width: auto;
            margin-top: 24px;
          }
        }

        .search-result-content{
          margin: 10px auto;
          width: 100%;
        }

        .content-body{
          width: 100%;
          .did-name {
            margin: 32px auto;
          }
          .consume-container {
            flex-direction: column;
            align-items: flex-end;

            .item {
              margin-bottom: 24px;
              font-size: 14px;
              .name {
                word-break: break-all;
              }

              &:first-child {
                margin-top: 32px;
              }
            }
          }
          .step-container{
            width: 100%;
            // flex-direction: column;
            // height: auto;
            margin-top: unset;
            padding: 16px 0;
            .step-item {
              width: unset;
              padding: 0;
              .prefix {
                margin: 0 4px;
              }
            }
          }
          .price-container {
            flex-direction: column-reverse;
            align-items: flex-end;
            .price {
              font-size: 14px;
              margin-bottom: 24px;
              word-break: break-all;
              .usdt-token {
                width: 18px;
              }
            }
            .label {
              display: flex;
              text-align: right;
              margin-bottom: 12px;

              .key{
                min-width: 60px;
              }
              .value{
                flex: 1;
                min-width: 0;
                word-break: break-all;
              }
            }
          }
        }
        .button-container {
          flex-direction: column;
          margin-top: 0;
        }
        .lists {
          height: auto;
          width: 100%;
          flex-direction: column;
          .list-container{
            width: 100%;
            margin-bottom: 24px;
            .dids{
              margin-top: 8px;
              max-height: 120px;
              min-height: 120px;
              .item{
                font-size: 14px;
              }
            }
          }
        }
      }
      .mydomain {
        width: 100%;
        min-height: auto;
        margin-bottom: 80px;
      }
    }
  }
}
</style>
